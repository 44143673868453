var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-none d-print-block" }, [
    _c("div", { staticClass: "container-fluid mb-4" }, [
      _vm._v(" " + _vm._s(_vm.baseUrl) + _vm._s(_vm.$route.fullPath) + " ")
    ]),
    _c(
      "div",
      { staticClass: "d-flex justify-content-center py-2 border-bottom" },
      [_c("Logo")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }