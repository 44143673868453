<template>
    <div id="cookie_consent_container" class="border-top py-3 d-print-none" v-if="showCookieMessage">
        <div class="container">
            <div class="d-flex align-items-md-center flex-column flex-md-row">
                <div class="flex-grow-1 text-center text-md-left" v-html="$t('frontend.cookieConsent.message', {0: privacyPolicyLink.url})"></div>
                <div class="mt-3 mt-md-0 ml-0 ml-md-4">
                    <button type="button" class="btn btn-primary text-nowrap w-100" id="cookie_consent_confirm" @click.prevent="setCookieConsentConfirmed">
                        {{ $t('frontend.cookieConsent.btn') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        data() {
            return {
                showCookieMessage: false
            }
        },
        created() {
            this.getPrivacyPolicyLink().then(() => {
                this.showCookieMessage = (this.getCookie("cookieConsent") != "1");
            });
        },
        computed: {
            ...mapGetters(['privacyPolicyLink'])
        },
        methods: {
            ...mapActions(['getPrivacyPolicyLink']),
            getCookie(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for(var i = 0; i <ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            setCookieConsentConfirmed() {
                this.setCookie("cookieConsent", "1", 365);
                this.showCookieMessage = false;
            }
        }
    }
</script>