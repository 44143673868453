var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border-bottom border-top position-relative d-print-none" },
    [
      _vm.$route.name != "Home"
        ? _c("div", { staticClass: "share-link-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "share-link",
                attrs: { href: "#", title: _vm.$t("vue.share.link_title") },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.copyToClipboard($event)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "sf-icon sf-share",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-lg-8 clinic-type-links-wrapper" },
            [
              _c(
                "div",
                { staticClass: "row mx-0 clinic-type-links" },
                _vm._l(_vm.hospitalCategories, function(category) {
                  return _c(
                    "a",
                    {
                      key: category.categoryId,
                      staticClass:
                        "col-12 col-sm-4 py-sm-4 d-flex flex-sm-column align-items-center flex-row py-3",
                      class: { active: _vm.isActive(category.categoryId) },
                      attrs: { href: _vm.getUrl(category.urlKey) },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.changePage(category.urlKey)
                        }
                      }
                    },
                    [
                      _c("SpfrIcon", { attrs: { icon: category.icon } }),
                      _c(
                        "span",
                        { staticClass: "mt-sm-2 text-center ml-2 ml-sm-0" },
                        [_vm._v(" " + _vm._s(category.name) + " ")]
                      ),
                      _vm.paginator &&
                      _vm.paginator.totalElements &&
                      _vm.isActive(category.categoryId)
                        ? _c(
                            "span",
                            { staticClass: "mt-sm-2 text-body ml-4 ml-sm-0" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "vue.hospital_category.count.found",
                                      { hospitals: _vm.paginator.totalElements }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }