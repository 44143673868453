<template>
    <fragment>
        <div class="container-fluid d-print-none">
            <div class="clinic-toolbar d-flex py-3 flex-wrap flex-lg-nowrap justify-content-between">
                <RegionFilterToggle :resetRegionFilter="reset_location_filter"/>
                <SyndromeFilterToggle v-if="$store.state.filter.categoryId == 'akut'"/>
                <Sorting/>
            </div>
        </div>
        <RegionFilter ref="reg_filter"/>
        <SyndromeFilter/>
    </fragment>
</template>
<script>
import RegionFilter from '@/components/RegionFilter'
import RegionFilterToggle from '@/components/RegionFilterToggle'
import SyndromeFilter from '@/components/SyndromeFilter'
import SyndromeFilterToggle from '@/components/SyndromeFilterToggle'
import Sorting from '@/components/Sorting'

export default {
    components: {
        RegionFilter,
        RegionFilterToggle,
        SyndromeFilter,
        SyndromeFilterToggle,
        Sorting
    },
    methods: {
        reset_location_filter() {
            this.$refs.reg_filter.reset_location_filter();
        }
    }
}

</script>
