var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c("div", { staticClass: "container-fluid d-print-none" }, [
        _c(
          "div",
          {
            staticClass:
              "clinic-toolbar d-flex py-3 flex-wrap flex-lg-nowrap justify-content-between"
          },
          [
            _c("RegionFilterToggle", {
              attrs: { resetRegionFilter: _vm.reset_location_filter }
            }),
            _vm.$store.state.filter.categoryId == "akut"
              ? _c("SyndromeFilterToggle")
              : _vm._e(),
            _c("Sorting")
          ],
          1
        )
      ]),
      _c("RegionFilter", { ref: "reg_filter" }),
      _c("SyndromeFilter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }