<template>
    <b-collapse id="syndrome_filter_collapse" v-model="syndromeFilterOpen" class="d-print-none">
        <div class="bg-light py-4">
            <div class="container-fluid">
                <div class="d-flex justify-content-between">
                    <strong class="current-category">{{ $t('vue.filter.syndrome.select_category') }}</strong>

                    <div class="term-switcher">
                        <a href="#" class="term-item mr-3" v-bind:class="{'text-secondary': !termSwitchOn}" v-on:click.prevent="useTechnicalTerms(false)">
                            {{ $t('vue.filter.syndrome.name_human') }}
                        </a>
                        <a href="#" class="term-item" v-bind:class="{'text-secondary': termSwitchOn}" v-on:click.prevent="useTechnicalTerms(true)">
                          {{ $t('vue.filter.syndrome.name_technical') }}
                        </a>
                    </div>
                </div>

                <transition name="fade" :duration="200" v-on:after-leave="afterLeaveCategories">
                    <div class="row syndrom-category-list mt-4" v-if="syndromeCategoryListVisible">
                        <div class="col-lg-2 mb-4" v-for="syndromCategory in syndromeCategoryList" :key="syndromCategory.id">
                            <a href="#" class="syndrom-category shadow border border-light p-4 d-flex flex-column align-items-center" @click.prevent="selectSyndromeCategory(syndromCategory.id)" >
                                <i :class="iconClass(syndromCategory)"></i>
                                <span>{{syndromCategory.name}}</span>
                            </a>
                        </div>
                    </div>
                </transition>
                
                <transition name="fade" :duration="200" v-on:after-leave="afterLeaveSyndromes">
                    <div class="row syndrom-list mt-2" v-if="mainSyndromeListVisible">
                        <div class="col-12 mb-3" >
                            <a href="#" class="back-to-categories" @click.prevent="showCategories">
                                <i class="fas fa-chevron-left mr-1"></i>
                                {{ $t('vue.back_to_overview')}}
                            </a>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-6 mb-4" v-for="mainSyndrome in mainSyndromes()" :key="mainSyndrome.id">
                            <div :class="`syndrom-item shadow p-3 h-100 d-flex flex-column justify-content-center align-items-start ${selectedParentId == mainSyndrome.id ? 'active' : ''}`" @click="selectMainSyndrome(mainSyndrome)">
                                <strong :title="syndromeNameOrText(mainSyndrome.name, mainSyndrome.term)">{{syndromeNameOrText(mainSyndrome.name, mainSyndrome.term)}}</strong>
                                <b-dropdown
                                    v-if="subSyndromes(mainSyndrome.id).length > 0"
                                    :id="`subSyndromeList_${mainSyndrome.id}`"
                                    variant="link"
                                    toggle-tag="a"
                                    toggle-class="p-0 sub-syndrom"
                                    menu-class="mt-0 p-0"
                                    offset="0"
                                    no-caret
                                    v-on:shown="subSyndromeMenuOpened(`subSyndromeList_${mainSyndrome.id}`)"
                                    v-on:hidden="subSyndromeMenuClosed(`subSyndromeList_${mainSyndrome.id}`)"
                                >
                                    <template v-slot:button-content @click.stop>
                                        <small v-if="selectedParentId == mainSyndrome.id && syndromeId != selectedParentId" :title="syndromeName">
                                            <span>{{termSwitchOn ? syndromeNameTech : syndromeName}}</span>
                                            <i class="fas fa-chevron-down ml-2"></i>
                                        </small>
                                        <small v-else>
                                            <span>{{ $t('vue.filter.syndrome.select_sub_category') }}</span>
                                            <i class="fas fa-chevron-down ml-2"></i>
                                        </small>
                                    </template>
                                    <b-dropdown-item
                                        v-for="subSyndrome in subSyndromes(mainSyndrome.id)"
                                        :key="subSyndrome.id"
                                        link-class="py-2 px-3"
                                        :active="syndromeId == subSyndrome.id"
                                        @click.stop="selectSubSyndrome(subSyndrome)"
                                    >
                                        {{syndromeNameOrText(subSyndrome.name, subSyndrome.term)}}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    created() {
        if (this.$store.state.syndromeCategoryList.length == 0) {
            this.getSyndromes().then(() => {
                this.setSelectedParendId();
            });
        } else {
            this.setSelectedParendId();
        }
    },
    computed: {
        ...mapGetters([
            'getHospitalsUrl'
        ]),
        ...mapGetters({
            filter: 'getFilter',
            syndromeCategoryList: 'getSyndromeCategoryList',
            syndromeList: 'getSyndromeList'
        }),
        syndromeId: {
            get() {
                return this.$store.state.filter.syndromeId;
            },
            set(newValue) {
                this.$store.state.filter.syndromeId = newValue;
                return newValue;
            }
        },
        syndromeUrlKey: {
            get() {
                return this.$store.state.filter.syndromeUrlKey;
            },
            set(newValue) {
                this.$store.state.filter.syndromeUrlKey = newValue;
                return newValue;
            }
        },
        syndromeName: {
            get() {
                return this.$store.state.filter.syndromeName;
            },
            set(newValue) {
                this.$store.state.filter.syndromeName = newValue;
                return newValue;
            }
        },
        syndromeNameTech: {
            get() {
                return this.$store.state.filter.syndromeNameTech;
            },
            set(newValue) {
                this.$store.state.filter.syndromeNameTech = newValue;
                return newValue;
            }
        },
        termSwitchOn: {
            get() {
                return this.$store.state.termSwitchOn;
            },
            set(newValue) {
                this.$store.state.termSwitchOn = newValue;
                return newValue;
            }
        },
        syndromeFilterOpen: {
            get() {
                return this.$store.state.syndromeFilterOpen;
            },
            set(newValue) {
                this.$store.state.syndromeFilterOpen = newValue;
                return newValue;
            }
        },
    },
    methods: {
        ...mapActions([
            'getSyndromes',
            'getHospitals'
        ]),
        afterLeaveCategories() {
            this.mainSyndromeListVisible = true;
        },
        afterLeaveSyndromes() {
            this.syndromeCategoryListVisible = true;
        },
        showCategories() {
            this.mainSyndromeListVisible = false;
        },
        subSyndromeMenuOpened(dropdown) {
            document.getElementById(dropdown).parentElement.dataset.dropdownOpen = true;
        },
        subSyndromeMenuClosed(dropdown) {
            document.getElementById(dropdown).parentElement.dataset.dropdownOpen = false;
        },
        setSelectedParendId() {
            if (this.$store.state.filter.syndromeId) {
                const selectedSyndrome = this.$store.state.syndromeList.find(syn => syn.id == this.$store.state.filter.syndromeId);
                if (selectedSyndrome.isMainLevel) {
                    this.selectedParentId = selectedSyndrome.id;
                } else {
                    this.selectedParentId = selectedSyndrome.parentId;
                }
            }
        },
        useTechnicalTerms(use) {
            this.termSwitchOn = use;
            if (this.syndromeId) {
                const sel = this.syndromeList.find(syn => syn.id == this.syndromeId);
                this.syndromeName = use ? sel.term : sel.name;
            }
        },
        iconClass (syndromCategory) {
            return "sf-icon " + syndromCategory.iconClassname + " mb-2";
        },
        mainSyndromes () {
            return this.syndromeList.filter(mainSyndrome => {
                return mainSyndrome.categoryId === this.selectedSyndromCategoryId && mainSyndrome.isMainLevel === true && mainSyndrome.hospitalCategory == "akut" && !(mainSyndrome.code == "" && this.subSyndromes(mainSyndrome.id).length == 0)
            })
        },
        subSyndromes (parentId) {
            return this.syndromeList.filter(syndrome1 => {
                return ((syndrome1.isMainLevel === false)  && (syndrome1.parentId === parentId))
            })
        },
        selectSyndromeCategory (categoryId) {
            this.selectedSyndromCategoryId = categoryId;
            this.syndromeCategoryListVisible = false;
        },
        selectMainSyndrome (syndrome) {
            if (syndrome.code != "") {
                this.syndromeId = syndrome.id;
                this.syndromeUrlKey = syndrome.urlKey;
                this.syndromeName = syndrome.name;
                this.syndromeNameTech = syndrome.term;
                this.selectedParentId = syndrome.id;

                const newUrl = this.getHospitalsUrl({
                    syndromeUrlKey: syndrome.urlKey,
                    categoryUrlKey: syndrome.categoryUrlKey,
                    sort: null,
                    pageNum: null
                });

                this.$router.push(newUrl);
            }
        },
        selectSubSyndrome (syndrome) {
            this.syndromeId = syndrome.id;
            this.syndromeUrlKey = syndrome.urlKey;
            this.syndromeName = syndrome.name;
            this.syndromeNameTech = syndrome.term;
            this.selectedParentId = syndrome.parentId;

            const newUrl = this.getHospitalsUrl({
                syndromeUrlKey: syndrome.urlKey,
                categoryUrlKey: syndrome.categoryUrlKey,
                sort: null,
                pageNum: null
            });

            this.$router.push(newUrl);
        },
        syndromeNameOrText (syndromeName, syndromeTerm) {
            if(this.termSwitchOn) {
                return syndromeTerm;
            } else {
                return syndromeName;
            }
        }
    },
    watch: {
        syndromeId(newV, oldV) {
            if (!newV) {
                this.selectedParentId = "";
            }
        }
    },
    data() {
        return {
            selectedSyndromCategoryId: "",
            syndromeCategoryListVisible: true,
            mainSyndromeListVisible: false,
            selectedParentId: "",
        }
    }
}
</script>
