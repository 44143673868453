<template>
    <div class="region-filter-wrapper order-1 text-nowrap d-flex justify-content-start mb-3 mb-md-0">
        <a id="region-filter-toggle" :class="`d-flex py-3 px-4 align-items-center text-body flex-grow-1 flex-md-grow-0 collapsed ${isFilterActive ? 'active' : ''}`" v-b-toggle="'region-filter-collapse'" role="button">
            <i class="sf-icon sf-ic-locate icon-big"></i>
            <span class="d-flex flex-column ml-3">
                <span>{{ $t('vue.filter.region.restrict') }}</span>
                <span class="filter-value">{{locationNameForButtonLabel}}</span>
            </span>
        </a>
        <button type="button" class="btn btn-outline-secondary px-3 filter-reset" :aria-label="$t('filter.syndrome.reset')" @click="resetRegionFilter">
            <SpfrIcon icon="nav-close"/>
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: [
        'resetRegionFilter',
    ],
    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
        isFilterActive() {
            if (this.filter.locationId || (this.filter.lon && this.filter.lat)) {
                return true
            }
            return false
        },
        locationNameForButtonLabel () {
            if (this.filter.locationId){
                return this.filter.locationName + ` - (${this.filter.range}km)`
            } else if(this.filter.lon && this.filter.lat) {
                return this.$store.state.isUsingLocation
                    ? this.$t('vue.filter.region.my_location', {'km': this.filter.range})
                    : this.$t('vue.filter.region.coordinates', {'km': this.filter.range})
            } else {
                return this.$t('vue.filter.region.no_restriction')
            }
        }
    }
}

</script>


