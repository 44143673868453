// i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

export let i18n = null

let loadedLanguages = []

function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }
  return axios.get(`/api/lang/${lang}.json`).then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs)
    setI18nLanguage(lang)
  }).catch(error => {
    let msgs = {}
    i18n.setLocaleMessage(lang, msgs)
    setI18nLanguage(lang)
  });
}

export function createI18n(lang) {
  loadedLanguages = []
  i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'de'
  })
  return i18n
}
