<template>
    <div id="app">
        <div class="page-wrapper">
            <Header/>
            <div id="main-content">
                <router-view/>
            </div>
            <Footer/>
            <CookieConsent/>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import { mapActions } from 'vuex'
    export default {
        name: 'App',
        created() {
            this.getHospitalCategories();
            this.getDefinitionLinkAkut();
            this.getDefinitionLinkAkutM();
            this.getDefinitionLinkAkutP();
            this.getDefinitionLinkAkutF();
            this.getDefinitionLinkReha();
            this.getDefinitionLinkPsych();
        },
        components: {
            Header,
            Footer,
            CookieConsent
        },
        methods: {
            ...mapActions([
                'getHospitalCategories',
                'getDefinitionLinkAkut',
                'getDefinitionLinkAkutM',
                'getDefinitionLinkAkutP',
                'getDefinitionLinkAkutF',
                'getDefinitionLinkReha',
                'getDefinitionLinkPsych',
            ]),
        }
    }
</script>
