<template>
    <div class="syndrom-filter-wrapper d-flex justify-content-center order-0 order-lg-2 mb-3 mb-lg-0">
        <a
            id="syndrom-filter-toggle"
            :class="`d-flex py-3 px-4 align-items-center text-body ${syndromeSelected ? 'active' : ''} flex-grow-1 flex-lg-grow-0 collapsed`"
            v-b-toggle="'syndrome_filter_collapse'"
            role="button"
            :title="($store.state.termSwitchOn ? syndromeNameTech : syndromeName) || $t('vue.filter.syndrome.no_selection')"
        >
            <SpfrIcon icon="diagnosis-other" iclass="icon-big"/>
            <span class="d-flex flex-column ml-3">
                <span>{{ $t('vue.filter.syndrome.diagnose') }}</span>
                <span v-if="syndromeSelected" class="filter-value">{{$store.state.termSwitchOn ? syndromeNameTech : syndromeName}}</span>
                <span v-else class="filter-value">{{ $t('vue.filter.syndrome.no_selection') }}</span>
            </span>
        </a>
        <button type="button" class="btn btn-outline-secondary px-3 filter-reset" :aria-label="$t('filter.syndrome.reset')" @click="syndrome_filter_reset">
            <SpfrIcon icon="nav-close"/>
        </button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'getHospitalsUrl',
            'getCategoryUrlKey'
        ]),
        syndromeId: {
            get() {
                return this.$store.state.filter.syndromeId;
            },
            set(newValue) {
                this.$store.state.filter.syndromeId = newValue;
                return newValue;
            }
        },
        syndromeName: {
            get() {
                return this.$store.state.filter.syndromeName;
            },
            set(newValue) {
                this.$store.state.filter.syndromeName = newValue;
                return newValue;
            }
        },
        syndromeNameTech: {
            get() {
                return this.$store.state.filter.syndromeNameTech;
            },
            set(newValue) {
                this.$store.state.filter.syndromeNameTech = newValue;
                return newValue;
            }
        },
        syndromeSelected () {
           return !!this.$store.state.filter.syndromeId;
        }
    },
    methods: {
        ...mapActions([
            'getHospitals'
        ]),
        syndrome_filter_reset() {
            this.syndromeId = "";
            this.syndromeName = "";
            this.syndromeNameTech = "";

            const newUrl = this.getHospitalsUrl({
                categoryUrlKey: this.getCategoryUrlKey,
                syndromeUrlKey: null,
                sort: this.$route.query.sort,
                pageNum: null
            });

            this.$router.push(newUrl);
        },
    }
}
</script>
