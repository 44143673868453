<template>
  <a class="hospital-rating-wrapper" v-if="!loading && hasRating" :href="'https://www.google.com/maps/place/?q=place_id:' + usedPlaceId" target="_blank">
    <div class="hospital-rating-stars-wrapper">
      <div class="hospital-rating-stars-empty">
        <FaIcon icon="star" itype="far" />
        <FaIcon icon="star" itype="far" />
        <FaIcon icon="star" itype="far" />
        <FaIcon icon="star" itype="far" />
        <FaIcon icon="star" itype="far" />
      </div>
      <div class="hospital-rating-stars-full" :style="{ width: rating * 20 + '%' }">
        <FaIcon icon="star" itype="fas" />
        <FaIcon icon="star" itype="fas" />
        <FaIcon icon="star" itype="fas" />
        <FaIcon icon="star" itype="fas" />
        <FaIcon icon="star" itype="fas" />
      </div>
    </div>
    <div class="hospital-rating-label">
      {{ rating }} ({{ ratingAmount }} {{ $t('vue.hospital.reviews') }})
    </div>
  </a>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

const API_KEY = 'AIzaSyAPSfkGXI794orRnn_gY2anQo7xkQJYsus';

export default {
  props: [
    "placeId",
    "groupPlaceId",
    "overallGroupPlaceId",
    "lat",
    "lng"
  ],
  data() {
    return {
      loading: false,
      hasRating: false,
      rating: false,
      ratingAmount: false,
      isGroupRating: false,
      usedPlaceId: ''
    }
  },
  created() {
    this.loading = true;
    this.isGroupRating = false;

    const loader = new Loader({
      apiKey: API_KEY,
      libraries: ["places"]
    });

    const mapOptions = {
      center: {
        lat: this.lat,
        lng: this.lng
      },
      zoom: 14
    };

    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(document.getElementById("hospital_gmap"), mapOptions);
        new google.maps.Marker({
          map,
          position: {
            lat: this.lat,
            lng: this.lng
          },
        });

        if (this.placeId) {
          const service = new google.maps.places.PlacesService(map);
          const placesRequest = {
            placeId: this.placeId,
            fields: ['rating', 'user_ratings_total']
          };
          
          service.getDetails(placesRequest, (_place, status) => {
            const place = _place || {};

            if (place.rating && status === 'OK') {
              this.hasRating = true,
              this.loading = false;
              this.rating = place.rating;
              this.ratingAmount = place.user_ratings_total;
              this.usedPlaceId = this.placeId;
            } else if (this.groupPlaceId) {
              const placesRequestGroup = {
                placeId: this.groupPlaceId,
                fields: ['rating', 'user_ratings_total']
              };

              service.getDetails(placesRequestGroup, (_gplace, gstatus) => {
                const gplace = _gplace || {};

                if (gplace.rating && gstatus === 'OK') {
                  this.hasRating = true,
                  this.loading = false;
                  this.rating = gplace.rating;
                  this.ratingAmount = gplace.user_ratings_total;
                  this.isGroupRating = true;
                  this.usedPlaceId = this.groupPlaceId;
                } else if (this.overallGroupPlaceId) {
                  const placesRequestOverallGroup = {
                    placeId: this.overallGroupPlaceId,
                    fields: ['rating', 'user_ratings_total']
                  };

                  service.getDetails(placesRequestOverallGroup, (_ogplace, ogstatus) => {
                    const ogplace = _ogplace || {};
                    this.loading = false;

                    if (ogstatus === 'OK' && ogplace) {
                      this.hasRating = true;
                      this.rating = ogplace.rating;
                      this.ratingAmount = ogplace.user_ratings_total;
                      this.isGroupRating = true;
                      this.usedPlaceId = this.overallGroupPlaceId;
                    }
                  });
                }
              });
            }
          });
        } else {
          this.loading = false;
        }
      })
      .catch(e => {
        this.loading = false;
      });
  }
};
</script>