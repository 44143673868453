import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Hospital from './views/Hospital.vue'
import Hospitals from './views/Hospitals.vue'

Vue.use(Router)

export function createRouter() {
    return new Router({
        mode: 'history',
        routes: [
            {path: '/', component: Home, name: 'Home'},

            // DE
            {path: '/spital/:hospitalUrlKey', component: Hospital, name: "SpitalDe"},
            {path: '/spital/:hospitalUrlKey/:syndromeUrlKey', component: Hospital, name: "SpitalDeSyndrom"},

            // FR
            {path: '/hosto/:hospitalUrlKey', component: Hospital, name: "SpitalFr"},
            {path: '/hosto/:hospitalUrlKey/:syndromeUrlKey', component: Hospital, name: "SpitalFrSyndrom"},

            {path: '/:categoryUrlKey', component: Hospitals},
            {path: '/:categoryUrlKey/:syndromeUrlKey', component: Hospitals},
        ]
    })
}
