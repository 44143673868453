<template>
    <div>
        <div class="toast-container">
            <div class="toast fade hide" role="alert" aria-live="assertive" aria-atomic="true" id="copy-to-clipboard-toast">
                <div class="toast-header">
                    <img src="../assets/toast-icon.png" height="16" width="16" class="rounded mr-2" alt="Spitalfinder Logo">
                    <strong class="mr-auto">{{ $t('vue.toast.default.title') }}</strong>
                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="toast-body">
                  {{ $t('vue.toast.copy_link.message') }}
                </div>
            </div>
        </div>

        <nav class="navbar navbar-light border-bottom bg-white fixed-top d-print-none">
            <div class="d-flex w-100 align-items-center">
                <router-link :class="'navbar-brand pt-2 pb-0 ' + logoClass" to="/" :title="$t('vue.link.homepage.title')" @click.native="resetHospitalList">
                    <Logo/>
                </router-link>
                <div class="d-flex flex-grow-1 justify-content-end">
                    <ul class="nav">
                        <li class="nav-item" v-for="item in navigationItems" :key="item.id">
                            <a class="nav-link" :href="item.url" :title="item.label">
                                <span>{{ item.label }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <b-dropdown variant="link">
                                <template v-slot:button-content>
                                    {{ $i18n.locale.toUpperCase() }}
                                </template>
                                <b-dropdown-item v-if="$i18n.locale == 'fr'" v-bind:href="deLink" @click="changeLanguage($event, 'de')">DE</b-dropdown-item>
                                <b-dropdown-item v-if="$i18n.locale == 'de'" v-bind:href="frLink" @click="changeLanguage($event, 'fr')">FR</b-dropdown-item>
                            </b-dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
    import { loadLanguageAsync } from '@/i18n'
    import Logo from '@/components/Logo'
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    export default {
        components: {
            Logo
        },
        created() {
            this.getNavigationItems()
        },
        computed: {
            ...mapGetters([
                'languageSwitcherUrls',
                'navigationItems'
            ]),
            deLink() {
              if (this.$route.name === "Home") {
                return window.location.href.replace("hostofinder", "spitalfinder")
              } else {
                return this.languageSwitcherUrls.de
              }
            },
            frLink() {
              if (this.$route.name === "Home") {
                return window.location.href.replace("spitalfinder", "hostofinder")
              } else {
                return this.languageSwitcherUrls.fr
              }
            },
            logoClass() {
                if (this.$route.name == "Home") {
                    return "invisible";
                }
                return "";
            }
        },
        methods: {
            ...mapMutations(['resetHospitalList']),
            ...mapActions(['getNavigationItems']),
            changeLanguage: function(event, lang) {
                // This is to provide language switch functionality to localhost and dev/staging servers
                const host = window.location.hostname.toLowerCase();
                if (host.indexOf("spitalfinder") == -1 && host.indexOf("hostofinder") == -1) {
                    event.preventDefault();
                    loadLanguageAsync(lang);
                }
            }
        }
    }
</script>
