var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    class: "sf-icon sf-ic-" + _vm.icon + (_vm.iclass ? " " + _vm.iclass : ""),
    attrs: { "aria-hidden": "true" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }