<template>
  <div v-if="mainStat.hasStats">
    <div class="detail-stat-list-item-wrapper my-2" v-if="mainStat.flags">
      <p class="mb-3 h5">
        <span v-html="$t('vue.' + statKey)"></span>
        <span v-if="mainStat.type === 'group' || mainStat.type === 'overallGroup'">
          {{ $t('vue.hospital.stats.group_value') }}
        </span>
      </p>
      <div class="detail-stat-list-subitem-wrapper pl-4">
        <div
          class="detail-stat-list-subitem d-flex align-items-center justify-content-between mb-3"
          v-for="key in Object.keys(mainStat.flags).sort()"
          :key="key"
        >
          <span class="text-wrap w-100 mr-5 flex-grow-1" v-html="getFlagLabel(statKey, key, yearKey, extraKey)"></span>
          <div class="rounded-circle stat-circle" :class="circleBgClass(mainStat.flags[key])" v-b-tooltip.hover :title="circleTooltipText(mainStat.flags[key], statKey)"></div>
        </div>
      </div>
    </div>
    <div v-else class="detail-stat-list-item d-flex align-items-center my-2">
      <span class="flex-grow-1 mr-5">
        <span v-html="$t('vue.' + statKey)"></span>
        <span v-if="mainStat.type === 'group' || mainStat.type === 'overallGroup'">
          {{ $t('vue.hospital.stats.group_value') }}
        </span>
      </span>
      <div class="rounded-circle stat-circle" :class="circleBgClass(mainStat.value)" v-b-tooltip.hover :title="circleTooltipText(mainStat.value, statKey)"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'mainStat',
    'statKey',
    'yearKey',
    'extraKey'
  ],
  methods: {
    getFlagLabel(statKey, flag, year, extraKey) {
      let defaultKey = 'vue.' + statKey + '.' + (extraKey ? extraKey + '.' : '') + flag
      if (year) {
        let keyWithYear = 'vue.' + statKey + '.' + (extraKey ? extraKey + '.' : '') + year + '.' + flag
        if (this.$te(keyWithYear)) {
          return this.$t(keyWithYear)
        }
      }
      return this.$t(defaultKey)
    },
    circleBgClass(flag) {
      if (flag === null || flag === undefined) {
        return "border";
      } else if (flag < 0.5) {
        return "bg-danger";
      } else if (flag < 1.5) {
        return "bg-dark";
      } else {
        return "bg-success";
      }
    },
    circleTooltipText(flag, key) {
      if (flag === null || flag === undefined) {
        return this.$t("rate.none");
      } else if (flag < 0.5) {
        switch (key) {
          case "patient_satisfaction":
          case "symptom_burdens":
          case "reha_success":
            return this.$t("rate.less_1");
          default:
            return this.$t("rate.more_2");
        }
      } else if (flag < 1.5) {
        return this.$t("rate.1_2");
      } else {
        switch (key) {
          case "patient_satisfaction":
          case "symptom_burdens":
          case "reha_success":
            return this.$t("rate.more_2");
          default:
            return this.$t("rate.less_1");
        }
      }
    },
  }
}
</script>