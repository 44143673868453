var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      staticClass:
        "sorting-wrapper dropdown order-3 text-nowrap d-flex justify-content-end",
      attrs: {
        right: "",
        "no-caret": "",
        variant: "link",
        id: "sorting-toggle",
        "toggle-class":
          "d-flex py-3 px-4 align-items-center flex-grow-1 flex-md-grow-0 text-body text-left text-md-right",
        "toggle-tag": "a"
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _c("i", { staticClass: "sf-icon sf-ic-list-arrow icon-big" }),
              _c("span", { staticClass: "d-flex flex-column ml-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("vue.sort")) + " "),
                _vm.sort
                  ? _c("span", { staticClass: "region-filter-value" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("vue.sort." + _vm.sort.toLowerCase())) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isVisible("patient_satisfaction")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("patient_satisfaction") },
              on: {
                click: function($event) {
                  return _vm.setSorting("patient_satisfaction")
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("vue.sort.patient_satisfaction")) + " "
              )
            ]
          )
        : _vm._e(),
      _vm.isVisible("wound_infections")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("wound_infections") },
              on: {
                click: function($event) {
                  return _vm.setSorting("wound_infections")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.wound_infections")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("falls")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("falls") },
              on: {
                click: function($event) {
                  return _vm.setSorting("falls")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.falls")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("pressure_sores")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("pressure_sores") },
              on: {
                click: function($event) {
                  return _vm.setSorting("pressure_sores")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.pressure_sores")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("rehospitalization")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("rehospitalization") },
              on: {
                click: function($event) {
                  return _vm.setSorting("rehospitalization")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.rehospitalization")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("symptom_burdens")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("symptom_burdens") },
              on: {
                click: function($event) {
                  return _vm.setSorting("symptom_burdens")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.symptom_burdens")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("rate_renewal_implantat")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("rate_renewal_implantat") },
              on: {
                click: function($event) {
                  return _vm.setSorting("rate_renewal_implantat")
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("vue.sort.rate_renewal_implantat")) + " "
              )
            ]
          )
        : _vm._e(),
      _vm.isVisible("reha_success")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("reha_success") },
              on: {
                click: function($event) {
                  return _vm.setSorting("reha_success")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.reha_success")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("name_asc")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("name_asc") },
              on: {
                click: function($event) {
                  return _vm.setSorting("name_asc")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.name_asc")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("name_desc")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("name_desc") },
              on: {
                click: function($event) {
                  return _vm.setSorting("name_desc")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.name_desc")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("distance")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("distance") },
              on: {
                click: function($event) {
                  return _vm.setSorting("distance")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.distance")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("number_of_cases")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("number_of_cases") },
              on: {
                click: function($event) {
                  return _vm.setSorting("number_of_cases")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.number_of_cases")) + " ")]
          )
        : _vm._e(),
      _vm.isVisible("mortality_ratio")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { active: _vm.isActive("mortality_ratio") },
              on: {
                click: function($event) {
                  return _vm.setSorting("mortality_ratio")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.sort.mortality_ratio")) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }