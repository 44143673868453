<template>
    <div class="d-none d-print-block pt-4">
        <PrintHeaderSimple/>
        <div class="container-fluid my-4">
            <div class="row">
                <div class="col-3" v-if="filter.categoryId">
                    <div class="d-flex flex-column">
                        <strong class="mb-1">{{ $t("hospitals.print.filter.category.label") }}:</strong>
                        <span>{{ $t(`vue.hospital_category.${filter.categoryId}.name`) }}</span>
                    </div>
                </div>
                <div class="col-3" v-if="filter.syndromeId">
                    <div class="d-flex flex-column">
                        <strong class="mb-1">{{ $t("hospitals.print.filter.syndrome.label") }}:</strong>
                        <span>{{ filter.syndromeName }}</span>
                    </div>
                </div>
                <div class="col-3" v-if="filter.range">
                    <div class="d-flex flex-column">
                        <strong class="mb-1">{{ $t("hospitals.print.filter.region.label") }}:</strong>
                        <span v-if="filter.locationId">{{ filter.locationName }} - {{ filter.range }}km</span>
                        <span v-else>({{ filter.lon }}, {{ filter.lat }}) - {{ filter.range }}km</span>
                    </div>
                </div>
                <div class="col-3" v-if="sort">
                    <div class="d-flex flex-column">
                        <strong class="mb-1">{{ $t("hospitals.print.sort.label") }}:</strong>
                        <span>{{ $t(`vue.sort.${sort.toLowerCase()}`) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PrintHeaderSimple from '@/components/PrintHeaderSimple'

export default {
    components: {
        PrintHeaderSimple
    },
    computed: {
        ...mapGetters({
            filter: 'getFilter',
            sort: 'getSorting',
        })
    }
}
</script>
