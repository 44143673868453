var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-none d-print-block pt-4" },
    [
      _c("PrintHeaderSimple"),
      _c("div", { staticClass: "container-fluid my-4" }, [
        _c("div", { staticClass: "row" }, [
          _vm.filter.categoryId
            ? _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("strong", { staticClass: "mb-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("hospitals.print.filter.category.label")) +
                        ":"
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "vue.hospital_category." +
                            _vm.filter.categoryId +
                            ".name"
                        )
                      )
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm.filter.syndromeId
            ? _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("strong", { staticClass: "mb-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("hospitals.print.filter.syndrome.label")) +
                        ":"
                    )
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.filter.syndromeName))])
                ])
              ])
            : _vm._e(),
          _vm.filter.range
            ? _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("strong", { staticClass: "mb-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("hospitals.print.filter.region.label")) +
                        ":"
                    )
                  ]),
                  _vm.filter.locationId
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.filter.locationName) +
                            " - " +
                            _vm._s(_vm.filter.range) +
                            "km"
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "(" +
                            _vm._s(_vm.filter.lon) +
                            ", " +
                            _vm._s(_vm.filter.lat) +
                            ") - " +
                            _vm._s(_vm.filter.range) +
                            "km"
                        )
                      ])
                ])
              ])
            : _vm._e(),
          _vm.sort
            ? _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("strong", { staticClass: "mb-1" }, [
                    _vm._v(_vm._s(_vm.$t("hospitals.print.sort.label")) + ":")
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("vue.sort." + _vm.sort.toLowerCase())))
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }