var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { staticClass: "page-wrapper" },
      [
        _c("Header"),
        _c("div", { attrs: { id: "main-content" } }, [_c("router-view")], 1),
        _c("Footer"),
        _c("CookieConsent")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }