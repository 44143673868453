export default {
  install (Vue) {
    Vue.prototype.$t = function (skey) {
      const key = (skey.indexOf("vue.") === 0 ? skey : 'vue.' + skey).replace(/ü/g, 'u');
      var values = [], len = arguments.length - 1;
      while ( len-- > 0 ) values[ len ] = arguments[ len + 1 ];
      return this.$i18n._t.apply(this.$i18n, [ key, this.$i18n.locale, this.$i18n._getMessages(), this ].concat( values ))
    }
  }
}
