var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "main-footer" }, [
    _c("div", { staticClass: "container py-4" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-md-6 d-flex justify-content-md-end" }, [
          _c(
            "ul",
            { staticClass: "list-unstyled footer-cms-links mb-0 mt-4 mt-md-0" },
            _vm._l(_vm.footerLinks, function(link) {
              return _c("li", { key: link.id, staticClass: "nav-item" }, [
                _c("a", { attrs: { href: link.url, title: link.label } }, [
                  _vm._v(" " + _vm._s(link.label) + " ")
                ])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "footer-logo mr-4 d-inline-block" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.santesuisse.ch/",
              title: "santésuisse Website"
            }
          },
          [
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: require("../assets/santesuisse_logo.png"),
                alt: "santésuisse Logo",
                height: "30",
                width: "93"
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "footer-logo d-inline-block" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.konsum.ch/",
              title: "Schweizerisches Konsumentenforum kf Website"
            }
          },
          [
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: require("../assets/logo-kf.png"),
                alt: "Schweizerisches Konsumentenforum kf Website",
                height: "30",
                width: "121"
              }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }