var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.hasRating
    ? _c(
        "a",
        {
          staticClass: "hospital-rating-wrapper",
          attrs: {
            href:
              "https://www.google.com/maps/place/?q=place_id:" +
              _vm.usedPlaceId,
            target: "_blank"
          }
        },
        [
          _c("div", { staticClass: "hospital-rating-stars-wrapper" }, [
            _c(
              "div",
              { staticClass: "hospital-rating-stars-empty" },
              [
                _c("FaIcon", { attrs: { icon: "star", itype: "far" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "far" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "far" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "far" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "far" } })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "hospital-rating-stars-full",
                style: { width: _vm.rating * 20 + "%" }
              },
              [
                _c("FaIcon", { attrs: { icon: "star", itype: "fas" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "fas" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "fas" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "fas" } }),
                _c("FaIcon", { attrs: { icon: "star", itype: "fas" } })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "hospital-rating-label" }, [
            _vm._v(
              " " +
                _vm._s(_vm.rating) +
                " (" +
                _vm._s(_vm.ratingAmount) +
                " " +
                _vm._s(_vm.$t("vue.hospital.reviews")) +
                ") "
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }