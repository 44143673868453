var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$i18n.locale == "fr"
    ? _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("../assets/LogoFR.png"),
          height: "71",
          width: "310",
          alt: _vm.$t("vue.hostofinder.logo_title")
        }
      })
    : _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("../assets/LogoDE.png"),
          height: "71",
          width: "311",
          alt: _vm.$t("vue.spitalfinder.logo_title")
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }