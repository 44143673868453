<template>
    <i aria-hidden="true" :class="(itype ? itype : 'fas') + ' fa-' + icon + (iclass ? ' ' + iclass : '')"></i>
</template>

<script>
export default {
  name: 'FaIcon',
  props: {
    icon: String,
    itype: String,
    iclass: String
  }
}
</script>
