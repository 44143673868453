var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-light d-print-none" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-lg-8" }, [
          _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("autocomplete", {
                ref: "autocomplete",
                attrs: {
                  id: "searchInputComponent",
                  name: "searchInputComponent",
                  "input-class":
                    "form-control form-control-lg autocomplete-main-input",
                  "results-display": _vm.formattedDisplay,
                  source: _vm.searchUrl,
                  "results-property": "Object",
                  placeholder: _vm.$t("vue.search.placeholder"),
                  "results-value": "id",
                  clearButtonIcon: "sf-icon sf-ic-nav-close",
                  showNoResults: false,
                  "aria-label": _vm.$t("vue.search.placeholder")
                },
                on: {
                  selected: _vm.search_selected,
                  space: _vm.gaHandlerSpace,
                  nothingSelected: _vm.search_nothingSelected
                }
              }),
              _c(
                "div",
                { attrs: { id: "search-error-toast-container" } },
                [
                  _c(
                    "b-toast",
                    {
                      attrs: {
                        id: "search-error-toast",
                        static: "",
                        solid: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "toast-title",
                          fn: function() {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("toast.default.title")) +
                                  " "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("vue.autocomplete.no_results")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }