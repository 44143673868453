var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card bg-light border-0 h-100" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("div", { staticClass: "card-body-info flex-grow-1" }, [
          _c(
            "h5",
            { staticClass: "card-title" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: _vm.hospitalUrl(_vm.hospital),
                    title: _vm.hospital.name
                  }
                },
                [_vm._v(" " + _vm._s(_vm.hospital.name) + " ")]
              )
            ],
            1
          ),
          _vm.hospital.categoryId === "reha"
            ? _c("h6", { staticClass: "card-subtitle text-muted" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.hospital.zip) +
                    " " +
                    _vm._s(_vm.hospital.city) +
                    " "
                )
              ])
            : _c("h6", { staticClass: "card-subtitle text-muted" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.hospital.zip) +
                    " " +
                    _vm._s(_vm.hospital.city) +
                    " - " +
                    _vm._s(_vm.hospital.typeName) +
                    " "
                )
              ])
        ])
      ]),
      _c("hr"),
      _c("p", { staticClass: "card-text" }, [
        _vm._v(" " + _vm._s(_vm.$t("vue.hospital.general_stats")) + " "),
        _c(
          "a",
          {
            staticClass: "float-right card-info-link",
            attrs: {
              href: _vm.definitionIdLink.url,
              title: _vm.definitionIdLink.label
            }
          },
          [_c("i", { staticClass: "sf-icon sf-ic-info-full" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "clinic-stats d-flex justify-content-start" },
        [
          _c("StatisticCircle", {
            attrs: {
              mainStat: _vm.hospitalPatientSatisfaction.value,
              hasStats: _vm.hospitalPatientSatisfaction.hasStats,
              year: _vm.hospitalPatientSatisfaction.year,
              flags: _vm.hospitalPatientSatisfaction.flags,
              statKey: "patient_satisfaction",
              hospitalId: _vm.hospital.id,
              extraKey: _vm.hospital.categoryId
            }
          }),
          _vm.hospital.categoryId === "akut" && !_vm.showSyndromeStats
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalWoundInfections.value,
                  hasStats: _vm.hospitalWoundInfections.hasStats,
                  year: _vm.hospitalWoundInfections.year,
                  flags: _vm.hospitalWoundInfections.flags,
                  statKey: "wound_infections",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e(),
          _vm.hospital.categoryId === "akut"
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalFalls.value,
                  hasStats: _vm.hospitalFalls.hasStats,
                  year: _vm.hospitalFalls.year,
                  flags: false,
                  statKey: "falls",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e(),
          _vm.hospital.categoryId === "akut"
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalPressureSores.value,
                  hasStats: _vm.hospitalPressureSores.hasStats,
                  year: _vm.hospitalPressureSores.year,
                  flags: _vm.hospitalPressureSores.flags,
                  statKey: "pressure_sores",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e(),
          _vm.hospital.categoryId === "akut"
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalRehospitalization.value,
                  hasStats: _vm.hospitalRehospitalization.hasStats,
                  year: _vm.hospitalRehospitalization.year,
                  flags: false,
                  statKey: "rehospitalization",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e(),
          _vm.hospital.categoryId === "akut" && !_vm.showSyndromeStats
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalRateRenewalImplantat.value,
                  hasStats: _vm.hospitalRateRenewalImplantat.hasStats,
                  year: _vm.hospitalRateRenewalImplantat.year,
                  flags: _vm.hospitalRateRenewalImplantat.flags,
                  statKey: "rate_renewal_implantat",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e(),
          _vm.hospital.categoryId === "psych"
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalSymptomBurdens.value,
                  hasStats: _vm.hospitalSymptomBurdens.hasStats,
                  year: _vm.hospitalSymptomBurdens.year,
                  flags: _vm.hospitalSymptomBurdens.flags,
                  statKey: "symptom_burdens",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e(),
          _vm.hospital.categoryId === "reha"
            ? _c("StatisticCircle", {
                attrs: {
                  mainStat: _vm.hospitalRehaSuccess.value,
                  hasStats: _vm.hospitalRehaSuccess.hasStats,
                  year: _vm.hospitalRehaSuccess.year,
                  flags: _vm.hospitalRehaSuccess.flags,
                  statKey: "reha_success",
                  hospitalId: _vm.hospital.id,
                  extraKey: false
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showSyndromeStats
        ? _c("div", [
            _c("hr"),
            _c("p", { staticClass: "card-text text-secondary" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.termSwitchOn
                      ? _vm.hospital.syndromeStats.syndromeTerm
                      : _vm.hospital.syndromeStats.syndromeName
                  )
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "row syndrom-stats align-items-center mx-n1" },
              [
                _c("div", { staticClass: "col-sm-6 col-12 px-1" }, [
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("vue.syndrome.number_of_cases")))
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm.syndromeCaseNumbers.value != null
                      ? _c("div", { staticClass: "col-md-8" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("vue.syndrome.number_of_cases.current")
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.syndromeCaseNumbers.value != null
                      ? _c("div", { staticClass: "col-md-4 syndrom-count" }, [
                          _vm.syndromeCaseNumbers.value
                            ? _c("strong", { staticClass: "text-secondary" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatNumber")(
                                        _vm.syndromeCaseNumbers.value
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("strong", { staticClass: "text-secondary" }, [
                                _vm._v("0")
                              ])
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm.syndromeCaseNumbers.value != null &&
                _vm.mainSyndromeCaseNumbers.mainSyndromeCaseNumbers !== null &&
                _vm.mainSyndromeCaseNumbers.percentage
                  ? _c(
                      "div",
                      { staticClass: "col-sm-6 col-12 mt-3 mt-sm-0 px-1" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("vue.syndrome.main_number_of_cases", {
                                main_syndrome_name:
                                  _vm.mainSyndromeCaseNumbers.mainSyndromeName,
                                main_number_of_cases: _vm.$formatNumber(
                                  _vm.mainSyndromeCaseNumbers
                                    .mainSyndromeCaseNumbers
                                )
                              })
                            )
                          )
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("vue.syndrome.percentage", {
                                syndrome_name:
                                  _vm.mainSyndromeCaseNumbers.syndromeName,
                                percentage: Number.parseFloat(
                                  _vm.mainSyndromeCaseNumbers.percentage * 100
                                ).toFixed(1)
                              })
                            )
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-12 px-1 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "clinic-stats d-flex justify-content-start"
                    },
                    [
                      _c("StatisticCircle", {
                        attrs: {
                          mainStat: _vm.syndromeWoundInfections.value,
                          hasStats: _vm.syndromeWoundInfections.hasStats,
                          flags: _vm.syndromeWoundInfections.flags,
                          statKey: "wound_infections",
                          hospitalId: _vm.hospital.id
                        }
                      }),
                      _vm.hospital.categoryId === "akut"
                        ? _c("StatisticCircle", {
                            attrs: {
                              mainStat: _vm.syndromeRateRenewalImplantat.value,
                              hasStats:
                                _vm.syndromeRateRenewalImplantat.hasStats,
                              flags: _vm.syndromeRateRenewalImplantat.flags,
                              statKey: "rate_renewal_implantat",
                              hospitalId: _vm.hospital.id
                            }
                          })
                        : _vm._e(),
                      _vm.syndromeFlag.value !== null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mb-2 d-flex flex-column justify-content-end align-items-center"
                            },
                            [
                              _c(
                                "small",
                                {
                                  staticClass:
                                    "stat-circle-title w-100 text-center mb-2"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("vue.syndrome.mortality")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottom",
                                      modifiers: { hover: true, bottom: true }
                                    }
                                  ],
                                  staticClass: "rounded-circle stat-circle",
                                  class: _vm.circleBgClass(
                                    _vm.syndromeFlag.value
                                  ),
                                  attrs: {
                                    title: _vm.circleTooltipText(
                                      _vm.syndromeFlag.value
                                    ),
                                    id:
                                      "clinic-stats-" +
                                      _vm.hospital.id +
                                      "-mortality",
                                    tabindex: "-1"
                                  }
                                },
                                [
                                  _vm.syndromeFlag.mortality !== null
                                    ? _c(
                                        "strong",
                                        { staticClass: "text-white" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.syndromeFlag.mortality
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "b-popover",
                                {
                                  staticClass: "d-none",
                                  attrs: {
                                    target:
                                      "clinic-stats-" +
                                      _vm.hospital.id +
                                      "-mortality",
                                    triggers: "focus"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("syndrome.mortality")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-dark float-right",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.$root.$emit(
                                                      "bv::hide::popover",
                                                      "clinic-stats-" +
                                                        _vm.hospital.id +
                                                        "-mortality"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "small",
                                                  [
                                                    _c("SpfrIcon", {
                                                      attrs: {
                                                        icon: "nav-close"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1324392467
                                  )
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("syndrome.mortality.popover")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(new Array(_vm.additionalStatsCount), function(
                        item,
                        index
                      ) {
                        return _c("div", { key: index })
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }