var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "region-filter-wrapper order-1 text-nowrap d-flex justify-content-start mb-3 mb-md-0"
    },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "region-filter-collapse",
              expression: "'region-filter-collapse'"
            }
          ],
          class:
            "d-flex py-3 px-4 align-items-center text-body flex-grow-1 flex-md-grow-0 collapsed " +
            (_vm.isFilterActive ? "active" : ""),
          attrs: { id: "region-filter-toggle", role: "button" }
        },
        [
          _c("i", { staticClass: "sf-icon sf-ic-locate icon-big" }),
          _c("span", { staticClass: "d-flex flex-column ml-3" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.filter.region.restrict")))]),
            _c("span", { staticClass: "filter-value" }, [
              _vm._v(_vm._s(_vm.locationNameForButtonLabel))
            ])
          ])
        ]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary px-3 filter-reset",
          attrs: {
            type: "button",
            "aria-label": _vm.$t("filter.syndrome.reset")
          },
          on: { click: _vm.resetRegionFilter }
        },
        [_c("SpfrIcon", { attrs: { icon: "nav-close" } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }