import {createApp} from './app'
import { loadLanguageAsync } from '@/i18n'

const {app, router, store, i18n} = createApp()

router.onReady(() => {
    if (window.__INITIAL_STATE__) {
        store.replaceState({...store.state, ...window.__INITIAL_STATE__})
    }
    loadLanguageAsync(i18n.locale).then(() => app.$mount('#app'))
})