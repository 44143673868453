import Vue from 'vue'
import App from './App.vue'
import {createRouter} from './router'
import {createStore} from './store'
import {sync} from 'vuex-router-sync'
import { initBaseComponents } from './utils/base-components'
import { createI18n } from './i18n'
import Paginate from 'vuejs-paginate'
import i18nPlugin from './utils/i18n-plugin'
import filters from './utils/filters'
import plugins from './utils/plugins'
import Fragment from 'vue-fragment'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";

// SCSS import
import './assets/scss/index.scss'

// BootstrapVue import
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueMeta)

Vue.use(i18nPlugin)
Vue.use(filters)
Vue.use(plugins)

Vue.use(Fragment.Plugin)

Vue.component('paginate', Paginate)

export function createApp() {
    const router = createRouter()
    const store = createStore()

    const host = window.location.host

    let language = "de"; // Fallback 
    if (host.indexOf("spitalfinder") > -1) {
        language = "de"
    } else if (host.indexOf("hostofinder") > -1) {
        language = "fr"
    }
    const i18n = createI18n(language)

    Vue.use(VueGtag, {
        config: { id: 'UA-70015844-1' },
    }, router);

    initBaseComponents()

    sync(store, router)

    const app = new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    })

    return {app, router, store, i18n}
}
