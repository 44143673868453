<template>
  <div v-if="hasStats" :class="boxClass || 'mb-2 d-flex flex-column justify-content-end align-items-center'">
    <small class="stat-circle-title w-100 text-center mb-2" v-html="$t('vue.' + statKey)"></small>
    <div
      class="rounded-circle stat-circle"
      :tabindex="(flags && Object.keys(flags).length) ? '-1' : 'none'"
      :id="'clinic-stats-' + hospitalId + '-' + statKey"
      :class="circleBgClass(mainStat)"
      :title="circleTooltip(mainStat)"
      v-b-tooltip.hover.bottom
    ></div>
    <b-popover
      v-if="mainStat !== null && flags && Object.keys(flags).length"
      class="d-none"
      :target="'clinic-stats-' + hospitalId + '-' + statKey"
      triggers="focus"
    >
      <template #title>
        <span v-html="$t('vue.' + statKey)"/>
        <a href="#" class="text-dark float-right" @click.prevent="$root.$emit('bv::hide::popover', 'clinic-stats-' + hospitalId + '-' + statKey)"><small><SpfrIcon icon="nav-close"/></small></a>
      </template>
      <div class="clinic-stats-detailed d-flex flex-wrap justify-content-center">
        <div
          class="clinic-stats-detailed-item d-flex flex-column align-items-center mb-3"
          v-for="key in Object.keys(flags).sort()"
          :key="key"
        >
          <small class="text-wrap w-100 text-center mb-2 flex-grow-1">
            {{ getLabel(key) }}
          </small>
          <div class="rounded-circle stat-circle" v-bind:class="circleBgClass(flags[key])"></div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  props: [
    "mainStat",
    "hasStats",
    "year",
    "flags",
    "statKey",
    "hospitalId",
    "extraKey",
    "boxClass"
  ],
  methods: {
    circleBgClass (flag) {
      if (flag === null) {
        return "border";
      } else if (flag < 0.5) {
        return "bg-danger";
      } else if (flag < 1.5) {
        return "bg-dark";
      } else {
        return "bg-success";
      }
    },
    circleTooltip (flag) {
      if (flag === null) {
        if (this.$te(`vue.rate.${this.statKey}.none`)) {
          return this.$t(`vue.rate.${this.statKey}.none`)
        } else {
          return this.$t("vue.rate.none")
        }
      } else if (flag < 0.5) {
        if (this.$te(`vue.rate.${this.statKey}.less_1`)) {
          return this.$t(`vue.rate.${this.statKey}.less_1`)
        } else {
          return this.$t("vue.rate.less_1")
        }
      } else if (flag < 1.5) {
        if (this.$te(`vue.rate.${this.statKey}.1_2`)) {
          return this.$t(`vue.rate.${this.statKey}.1_2`)
        } else {
          return this.$t("vue.rate.1_2")
        }
      } else {
        if (this.$te(`vue.rate.${this.statKey}.more_2`)) {
          return this.$t(`vue.rate.${this.statKey}.more_2`)
        } else {
          return this.$t("vue.rate.more_2")
        }
      }
    },
    getLabel(flag) {
      let defaultKey = 'vue.' + this.statKey + '.' + (this.extraKey ? this.extraKey + '.' : '') + flag
      if (this.year) {
        let keyWithYear = 'vue.' + this.statKey + '.' + (this.extraKey ? this.extraKey + '.' : '') + this.year + '.' + flag
        if (this.$te(keyWithYear)) {
          return this.$t(keyWithYear)
        }
      }
      return this.$t(defaultKey)
    }
  },
};
</script>