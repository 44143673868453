<template>
<div class="border-bottom border-top position-relative d-print-none">
  <div class="share-link-wrapper" v-if="$route.name != 'Home'">
    <a href="#" @click.prevent="copyToClipboard($event)" class="share-link" v-bind:title="$t('vue.share.link_title')">
      <i class="sf-icon sf-share" aria-hidden="true"></i>
    </a>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 clinic-type-links-wrapper">
        <div class="row mx-0 clinic-type-links">
            <a v-for="category in hospitalCategories" :key="category.categoryId" :href="getUrl(category.urlKey)"
               @click.prevent="changePage(category.urlKey)"
               v-bind:class="{ active: isActive(category.categoryId) }"
               class="col-12 col-sm-4 py-sm-4 d-flex flex-sm-column align-items-center flex-row py-3">
              <SpfrIcon v-bind:icon="category.icon"/>
              <span class="mt-sm-2 text-center ml-2 ml-sm-0">
                {{ category.name }}
              </span>
              <span class="mt-sm-2 text-body ml-4 ml-sm-0" v-if="paginator && paginator.totalElements && isActive(category.categoryId)">
                {{ $t('vue.hospital_category.count.found', { hospitals: paginator.totalElements}) }}
              </span>
            </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { copyTextToClipboard } from '@/utils/helpers'

  export default {
    computed: {
      ...mapGetters([
        'getHospitalsUrl',
        'hospitalCategories'
      ]),
      ...mapGetters({
        sort: 'getSorting',
        filter: 'getFilter',
        paginator: 'getPaginator'
      }),
    },
    methods: {
      ...mapActions([
        'getHospitals'
      ]),
      isActive(categoryId) {
        if (this.filter) {
          return categoryId === this.filter.categoryId
        } else {
          return false
        }
      },
      getUrl(categoryUrlKey) {
        return this.getHospitalsUrl({
          categoryUrlKey,
          syndromeUrlKey: null,
          sort: null,
          pageNum: null
        });
      },
      changePage(urlKey) {
        let newUrl = this.getUrl(urlKey);
        this.$store.state.syndromeFilterOpen = false;
        this.$router.push(newUrl);
      },
      copyToClipboard() {
        copyTextToClipboard(location.href)
        this.$toast(this.$t('toast.copy_link.message'))
      }
    }
  }
</script>
