var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Search"),
      _c("PrintHeaderSimple"),
      !_vm.loading && _vm.hospital && _vm.hospital.name
        ? _c("div", [
            _c(
              "div",
              { staticClass: "container-fluid clinic-detail-info py-3" },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-between d-print-none"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.goBack()
                          }
                        }
                      },
                      [
                        _c("FaIcon", {
                          attrs: { icon: "chevron-left", iclass: "mr-1" }
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("vue.btn.back_to_search")) + " "
                        )
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "share-link",
                        attrs: { href: "#", title: "Link Teilen" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.copyToClipboard()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "sf-icon sf-share",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "bg-light mt-3 p-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xl-9" }, [
                      _c("h1", [_vm._v(_vm._s(_vm.hospital.name))]),
                      _vm.groupName
                        ? _c("p", { staticClass: "h2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("vue.hospital.group", {
                                    name: _vm.groupName
                                  })
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row clinic-detail-info-main" },
                        [
                          _c("div", { staticClass: "col-md-6" }, [
                            _vm.hospital.categoryId === "reha"
                              ? _c("p", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.hospital.categoryName) +
                                      " "
                                  )
                                ])
                              : _c("p", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.hospital.categoryName) +
                                      " - " +
                                      _vm._s(_vm.hospital.typeName) +
                                      " "
                                  )
                                ]),
                            _c(
                              "p",
                              [
                                _c("HospitalRating", {
                                  attrs: {
                                    placeId: _vm.hospital.googlePlaceId,
                                    groupPlaceId:
                                      _vm.hospital.googlePlaceIdGroup,
                                    overallGroupPlaceId:
                                      _vm.hospital.googlePlaceIdOverallGroup,
                                    lat: _vm.hospital.lat,
                                    lng: _vm.hospital.lng
                                  }
                                })
                              ],
                              1
                            ),
                            _c("address", [
                              _vm._v(" " + _vm._s(_vm.hospital.name)),
                              _c("br"),
                              _vm._v(" " + _vm._s(_vm.hospital.address)),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.hospital.zip) +
                                  " " +
                                  _vm._s(_vm.hospital.city) +
                                  " "
                              )
                            ]),
                            _c("ul", { staticClass: "list-unstyled pt-2" }, [
                              _vm.hospital.lng && _vm.hospital.lat
                                ? _c("li", { staticClass: "mb-2" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          rel: "noreferrer",
                                          href:
                                            "https://maps.google.com/?q=" +
                                            _vm.hospital.lat +
                                            "," +
                                            _vm.hospital.lng,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-map-marker-alt mr-1"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("vue.hospital.google_maps")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.hospital.website
                                ? _c("li", { staticClass: "mb-2" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          rel: "noreferrer",
                                          href: _vm.hospital.website,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "sf-icon sf-ic-share mr-1"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("vue.hospital.website")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.hospital.phone
                                ? _c("li", { staticClass: "mb-2" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "tel:" + _vm.hospital.phone
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "sf-icon sf-ic-phone mr-1"
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.hospital.phone) + " "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c(
                              "div",
                              {
                                staticClass: "stat-source d-flex mt-5 mt-md-0"
                              },
                              [
                                _c(
                                  "b-dropdown",
                                  {
                                    attrs: {
                                      variant: "link",
                                      "toggle-tag": "a",
                                      "toggle-class": "p-0"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function() {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.hospital.hospitalStats
                                                      .year
                                                      ? _vm.$t(
                                                          "vue.hospital.general_stats_year",
                                                          {
                                                            year:
                                                              _vm.hospital
                                                                .hospitalStats
                                                                .year
                                                          }
                                                        )
                                                      : _vm.$t(
                                                          "vue.hospital.latest_year"
                                                        )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      4124731371
                                    )
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          active:
                                            null ==
                                            _vm.hospital.hospitalStats.year
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeYear(null)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "vue.hospital.latest_year_entry"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.hospital.hospitalStats.availableYears,
                                      function(availableYear) {
                                        return _c(
                                          "b-dropdown-item",
                                          {
                                            key: availableYear,
                                            attrs: {
                                              active:
                                                availableYear ==
                                                _vm.hospital.hospitalStats.year
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeYear(
                                                  availableYear
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(availableYear) + " "
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.bottom",
                                        modifiers: { hover: true, bottom: true }
                                      }
                                    ],
                                    staticClass: "ml-4",
                                    attrs: {
                                      href: "#",
                                      title: _vm.$t(
                                        "vue.hospital.hospital_stats_info"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "sf-icon sf-ic-info-full text-black-50"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "detail-stat-list d-inline-flex flex-column mt-4 mw-100 position-relative"
                              },
                              [
                                _c("StatisticCircleDetail", {
                                  attrs: {
                                    mainStat: _vm.hospitalPatientSatisfaction,
                                    statKey: "patient_satisfaction",
                                    yearKey:
                                      _vm.hospitalPatientSatisfaction.year,
                                    extraKey: _vm.hospital.categoryId
                                  }
                                }),
                                _vm.hospital.categoryId === "akut" &&
                                !_vm.showSyndromeStats
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat: _vm.hospitalWoundInfections,
                                        statKey: "wound_infections"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.hospital.categoryId === "akut"
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat: _vm.hospitalPressureSores,
                                        statKey: "pressure_sores",
                                        yearKey: _vm.hospitalPressureSores.year
                                      }
                                    })
                                  : _vm._e(),
                                _vm.hospital.categoryId === "psych"
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat: _vm.hospitalSymptomBurdens,
                                        statKey: "symptom_burdens",
                                        yearKey: _vm.hospitalSymptomBurdens.year
                                      }
                                    })
                                  : _vm._e(),
                                _vm.hospital.categoryId === "reha"
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat: _vm.hospitalRehaSuccess,
                                        statKey: "reha_success",
                                        yearKey: _vm.hospitalRehaSuccess.year
                                      }
                                    })
                                  : _vm._e(),
                                _vm.hospital.categoryId === "akut"
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat: _vm.hospitalFalls,
                                        statKey: "falls"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.hospital.categoryId === "akut"
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat: _vm.hospitalRehospitalization,
                                        statKey: "rehospitalization"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.hospital.categoryId === "akut" &&
                                !_vm.showSyndromeStats
                                  ? _c("StatisticCircleDetail", {
                                      attrs: {
                                        mainStat:
                                          _vm.hospitalRateRenewalImplantat,
                                        statKey: "rate_renewal_implantat",
                                        yearKey:
                                          _vm.hospitalRateRenewalImplantat.year
                                      }
                                    })
                                  : _vm._e(),
                                _vm.loadingStats
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "loading-overlay-centered"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "py-5 my-5 d-flex justify-content-center"
                                          },
                                          [
                                            _c("FaIcon", {
                                              attrs: {
                                                icon: "circle-notch",
                                                iclass:
                                                  "fa-spin fa-3x text-primary"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]),
                    _vm.hospital.lng && _vm.hospital.lat
                      ? _c(
                          "div",
                          { staticClass: "col-xl-3 mt-4 mt-xl-0 d-print-none" },
                          [
                            _c("div", {
                              style: { width: "100%", height: "400px" },
                              attrs: { id: "hospital_gmap" }
                            })
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            ),
            _vm.showSyndromeStats
              ? _c(
                  "div",
                  { staticClass: "container-fluid clinic-detail-syndrom pb-3" },
                  [
                    _c("div", { staticClass: "bg-light p-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "syndrom-title mb-3 d-flex flex-column flex-md-row flex-wrap align-items-start align-items-md-center"
                        },
                        [
                          _c(
                            "h2",
                            { staticClass: "d-inline text-secondary h3 mr-4" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$store.state.termSwitchOn
                                    ? _vm.hospital.syndromeStats.syndromeTerm
                                    : _vm.hospital.syndromeStats.syndromeName
                                )
                              )
                            ]
                          ),
                          _c("span", [
                            _c("span", { staticClass: "h4" }, [
                              _vm._v(_vm._s(_vm.hospital.name))
                            ]),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.bottom",
                                    modifiers: { hover: true, bottom: true }
                                  }
                                ],
                                staticClass: "ml-4",
                                attrs: {
                                  title: _vm.$t(
                                    "vue.hospital.syndrome_stats_info"
                                  )
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "sf-icon sf-ic-info-full text-black-50"
                                })
                              ]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-3 clinic-syndrom-info d-md-inline-flex d-flex flex-column flex-md-row position-relative d-print-inline-block"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-flex flex-column d-print-inline-block"
                            },
                            [
                              _vm.syndromeCaseNumbers.value !== null
                                ? _c("div", { staticClass: "my-2 d-flex" }, [
                                    _c("div", { staticClass: "flex-grow-1" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "vue.syndrome.number_of_cases"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _vm.syndromeCaseNumbers.type ===
                                        "group" ||
                                      _vm.syndromeCaseNumbers.type ===
                                        "overallGroup"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "vue.hospital.stats.group_value"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    _c("div", { staticClass: "ml-5" }, [
                                      _vm.syndromeCaseNumbers.value
                                        ? _c(
                                            "strong",
                                            { staticClass: "text-secondary" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      _vm.syndromeCaseNumbers
                                                        .value
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "strong",
                                            { staticClass: "text-secondary" },
                                            [_vm._v("0")]
                                          )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm.syndromeFlag.value !== null
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "my-2 d-flex align-items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-grow-1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("vue.syndrome.mortality")
                                              ) +
                                              " "
                                          ),
                                          _vm.syndromeFlag.type === "group" ||
                                          _vm.syndromeFlag.type ===
                                            "overallGroup"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "vue.hospital.stats.group_value"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("div", { staticClass: "ml-5" }, [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                modifiers: { hover: true }
                                              }
                                            ],
                                            staticClass:
                                              "rounded-circle stat-circle",
                                            class: _vm.circleBgClass(
                                              _vm.syndromeFlag.value
                                            ),
                                            attrs: {
                                              title: _vm.circleTooltipText(
                                                _vm.syndromeFlag.value
                                              )
                                            }
                                          },
                                          [
                                            _vm.syndromeFlag.mortality !== null
                                              ? _c(
                                                  "strong",
                                                  { staticClass: "text-white" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.syndromeFlag
                                                            .mortality
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _c("StatisticCircleDetail", {
                                attrs: {
                                  mainStat: _vm.syndromeWoundInfections,
                                  statKey: "wound_infections",
                                  yearKey: _vm.syndromeWoundInfections.year
                                }
                              }),
                              _c("StatisticCircleDetail", {
                                attrs: {
                                  mainStat: _vm.syndromeRateRenewalImplantat,
                                  statKey: "rate_renewal_implantat",
                                  yearKey: _vm.syndromeRateRenewalImplantat.year
                                }
                              })
                            ],
                            1
                          ),
                          _vm.mainSyndromeCaseNumbers
                            .mainSyndromeCaseNumbers !== null &&
                          _vm.mainSyndromeCaseNumbers.percentage
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-inline-flex flex-column ml-0 ml-md-5"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "my-2 mx-0 mx-md-3" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "vue.syndrome.main_number_of_cases",
                                              {
                                                main_syndrome_name:
                                                  _vm.mainSyndromeCaseNumbers
                                                    .mainSyndromeName,
                                                main_number_of_cases: _vm.$formatNumber(
                                                  _vm.mainSyndromeCaseNumbers
                                                    .mainSyndromeCaseNumbers
                                                )
                                              }
                                            )
                                          )
                                        )
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("vue.syndrome.percentage", {
                                              syndrome_name:
                                                _vm.mainSyndromeCaseNumbers
                                                  .syndromeName,
                                              percentage: Number.parseFloat(
                                                _vm.mainSyndromeCaseNumbers
                                                  .percentage * 100
                                              ).toFixed(1)
                                            })
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.loadingStats
                            ? _c(
                                "div",
                                { staticClass: "loading-overlay-centered" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center"
                                    },
                                    [
                                      _c("FaIcon", {
                                        attrs: {
                                          icon: "circle-notch",
                                          iclass: "fa-spin fa-3x text-primary"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "py-5 d-flex justify-content-center" },
              [
                _c("FaIcon", {
                  attrs: {
                    icon: "circle-notch",
                    iclass: "fa-spin fa-3x text-primary"
                  }
                })
              ],
              1
            )
          ])
        : _c("div", [
            _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(" " + _vm._s(_vm.$t("vue.hospital.not_found")) + " ")
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }