var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mainStat.hasStats
    ? _c("div", [
        _vm.mainStat.flags
          ? _c("div", { staticClass: "detail-stat-list-item-wrapper my-2" }, [
              _c("p", { staticClass: "mb-3 h5" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.$t("vue." + _vm.statKey)) }
                }),
                _vm.mainStat.type === "group" ||
                _vm.mainStat.type === "overallGroup"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("vue.hospital.stats.group_value")) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "detail-stat-list-subitem-wrapper pl-4" },
                _vm._l(Object.keys(_vm.mainStat.flags).sort(), function(key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "detail-stat-list-subitem d-flex align-items-center justify-content-between mb-3"
                    },
                    [
                      _c("span", {
                        staticClass: "text-wrap w-100 mr-5 flex-grow-1",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getFlagLabel(
                              _vm.statKey,
                              key,
                              _vm.yearKey,
                              _vm.extraKey
                            )
                          )
                        }
                      }),
                      _c("div", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "rounded-circle stat-circle",
                        class: _vm.circleBgClass(_vm.mainStat.flags[key]),
                        attrs: {
                          title: _vm.circleTooltipText(
                            _vm.mainStat.flags[key],
                            _vm.statKey
                          )
                        }
                      })
                    ]
                  )
                }),
                0
              )
            ])
          : _c(
              "div",
              {
                staticClass:
                  "detail-stat-list-item d-flex align-items-center my-2"
              },
              [
                _c("span", { staticClass: "flex-grow-1 mr-5" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("vue." + _vm.statKey))
                    }
                  }),
                  _vm.mainStat.type === "group" ||
                  _vm.mainStat.type === "overallGroup"
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("vue.hospital.stats.group_value")) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "rounded-circle stat-circle",
                  class: _vm.circleBgClass(_vm.mainStat.value),
                  attrs: {
                    title: _vm.circleTooltipText(
                      _vm.mainStat.value,
                      _vm.statKey
                    )
                  }
                })
              ]
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }