var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCookieMessage
    ? _c(
        "div",
        {
          staticClass: "border-top py-3 d-print-none",
          attrs: { id: "cookie_consent_container" }
        },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-md-center flex-column flex-md-row"
              },
              [
                _c("div", {
                  staticClass: "flex-grow-1 text-center text-md-left",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("frontend.cookieConsent.message", {
                        0: _vm.privacyPolicyLink.url
                      })
                    )
                  }
                }),
                _c("div", { staticClass: "mt-3 mt-md-0 ml-0 ml-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary text-nowrap w-100",
                      attrs: { type: "button", id: "cookie_consent_confirm" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.setCookieConsentConfirmed($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("frontend.cookieConsent.btn")) + " "
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }