var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-collapse",
    { staticClass: "d-print-none", attrs: { id: "region-filter-collapse" } },
    [
      _c("div", { staticClass: "bg-light py-4" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row justify-content-around" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-5" },
              [
                _c("label", { attrs: { for: "locationTag" } }, [
                  _vm._v(_vm._s(_vm.$t("vue.filter.region.input_title")))
                ]),
                _c("autocomplete", {
                  ref: "reg_autocomplete",
                  attrs: {
                    id: "locationTag",
                    name: "locationId",
                    "input-class": "form-control form-control-lg",
                    "results-display": _vm.locationFormattedDisplay,
                    source: "/api/location-suggestions.json?q=",
                    "results-property": "Object",
                    placeholder: _vm.$t("vue.filter.region.input_placeholder"),
                    initialValue: _vm.filter.locationId,
                    initialDisplay: _vm.filter.locationName,
                    "results-value": "id",
                    clearButtonIcon: "sf-icon sf-ic-nav-close"
                  },
                  on: {
                    selected: _vm.location_selected,
                    clear: _vm.location_clear,
                    nothingSelected: _vm.onEnterKey
                  }
                }),
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "button",
                    {
                      class:
                        "btn btn-outline-secondary btn-block " +
                        (_vm.isUsingLocationInternal ? "active" : ""),
                      attrs: {
                        type: "button",
                        disabled: !_vm.geolocationSupported
                      },
                      on: {
                        click: function($event) {
                          return _vm.resolveUserLocation()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "sf-icon sf-ic-locate mr-2" }),
                      _vm._v(
                        _vm._s(
                          _vm.isUsingLocationInternal
                            ? _vm.$t("vue.filter.region.geolocation_mine_used")
                            : _vm.$t("vue.filter.region.geolocation_mine_use")
                        ) + " "
                      )
                    ]
                  ),
                  _vm.geolocationNoPermissions
                    ? _c("div", { staticClass: "alert alert-danger mt-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "vue.filter.region.geolocation_no_permissions"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.geolocationFailed
                    ? _c("div", { staticClass: "alert alert-danger mt-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("vue.filter.region.geolocation_failed")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ])
              ],
              1
            ),
            _c("div", { staticClass: "col-md-6 col-lg-5 mt-4 mt-md-0" }, [
              _c("label", { attrs: { for: "filter-range" } }, [
                _vm._v(_vm._s(_vm.$t("vue.filter.region.range")) + " "),
                _c(
                  "strong",
                  {
                    staticClass: "text-secondary ml-3",
                    attrs: { id: "filter-range-value" }
                  },
                  [_vm._v(_vm._s(_vm.range) + "km")]
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "filter-range-slider-wrapper d-flex align-items-center"
                },
                [
                  _c("span", { staticClass: "mr-3" }, [
                    _vm._v(_vm._s(_vm.$t("vue.filter.region.range_default")))
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.range,
                        expression: "range"
                      }
                    ],
                    staticClass: "custom-range mt-1",
                    attrs: {
                      type: "range",
                      name: "range",
                      min: "3",
                      max: "350",
                      id: "filter-range"
                    },
                    domProps: { value: _vm.range },
                    on: {
                      change: _vm.rangeChanged,
                      __r: function($event) {
                        _vm.range = $event.target.value
                      }
                    }
                  }),
                  _c("span", { staticClass: "text-nowrap ml-3" }, [
                    _vm._v(
                      _vm._s(_vm.$t("vue.filter.region.geolocation_default"))
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "mt-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-dark btn-block",
                    attrs: { type: "button" },
                    on: { click: _vm.reset_location_filter }
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.reset")))]
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }