var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-collapse",
    {
      staticClass: "d-print-none",
      attrs: { id: "syndrome_filter_collapse" },
      model: {
        value: _vm.syndromeFilterOpen,
        callback: function($$v) {
          _vm.syndromeFilterOpen = $$v
        },
        expression: "syndromeFilterOpen"
      }
    },
    [
      _c("div", { staticClass: "bg-light py-4" }, [
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("strong", { staticClass: "current-category" }, [
                _vm._v(_vm._s(_vm.$t("vue.filter.syndrome.select_category")))
              ]),
              _c("div", { staticClass: "term-switcher" }, [
                _c(
                  "a",
                  {
                    staticClass: "term-item mr-3",
                    class: { "text-secondary": !_vm.termSwitchOn },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.useTechnicalTerms(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("vue.filter.syndrome.name_human")) +
                        " "
                    )
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "term-item",
                    class: { "text-secondary": _vm.termSwitchOn },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.useTechnicalTerms(true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("vue.filter.syndrome.name_technical")) +
                        " "
                    )
                  ]
                )
              ])
            ]),
            _c(
              "transition",
              {
                attrs: { name: "fade", duration: 200 },
                on: { "after-leave": _vm.afterLeaveCategories }
              },
              [
                _vm.syndromeCategoryListVisible
                  ? _c(
                      "div",
                      { staticClass: "row syndrom-category-list mt-4" },
                      _vm._l(_vm.syndromeCategoryList, function(
                        syndromCategory
                      ) {
                        return _c(
                          "div",
                          {
                            key: syndromCategory.id,
                            staticClass: "col-lg-2 mb-4"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "syndrom-category shadow border border-light p-4 d-flex flex-column align-items-center",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.selectSyndromeCategory(
                                      syndromCategory.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  class: _vm.iconClass(syndromCategory)
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(syndromCategory.name))
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "transition",
              {
                attrs: { name: "fade", duration: 200 },
                on: { "after-leave": _vm.afterLeaveSyndromes }
              },
              [
                _vm.mainSyndromeListVisible
                  ? _c(
                      "div",
                      { staticClass: "row syndrom-list mt-2" },
                      [
                        _c("div", { staticClass: "col-12 mb-3" }, [
                          _c(
                            "a",
                            {
                              staticClass: "back-to-categories",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.showCategories($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-chevron-left mr-1"
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("vue.back_to_overview")) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _vm._l(_vm.mainSyndromes(), function(mainSyndrome) {
                          return _c(
                            "div",
                            {
                              key: mainSyndrome.id,
                              staticClass: "col-xl-3 col-lg-4 col-md-6 mb-4"
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "syndrom-item shadow p-3 h-100 d-flex flex-column justify-content-center align-items-start " +
                                    (_vm.selectedParentId == mainSyndrome.id
                                      ? "active"
                                      : ""),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectMainSyndrome(
                                        mainSyndrome
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "strong",
                                    {
                                      attrs: {
                                        title: _vm.syndromeNameOrText(
                                          mainSyndrome.name,
                                          mainSyndrome.term
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.syndromeNameOrText(
                                            mainSyndrome.name,
                                            mainSyndrome.term
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm.subSyndromes(mainSyndrome.id).length > 0
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          attrs: {
                                            id:
                                              "subSyndromeList_" +
                                              mainSyndrome.id,
                                            variant: "link",
                                            "toggle-tag": "a",
                                            "toggle-class": "p-0 sub-syndrom",
                                            "menu-class": "mt-0 p-0",
                                            offset: "0",
                                            "no-caret": ""
                                          },
                                          on: {
                                            shown: function($event) {
                                              return _vm.subSyndromeMenuOpened(
                                                "subSyndromeList_" +
                                                  mainSyndrome.id
                                              )
                                            },
                                            hidden: function($event) {
                                              return _vm.subSyndromeMenuClosed(
                                                "subSyndromeList_" +
                                                  mainSyndrome.id
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function() {
                                                  return [
                                                    _vm.selectedParentId ==
                                                      mainSyndrome.id &&
                                                    _vm.syndromeId !=
                                                      _vm.selectedParentId
                                                      ? _c(
                                                          "small",
                                                          {
                                                            attrs: {
                                                              title:
                                                                _vm.syndromeName
                                                            }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.termSwitchOn
                                                                    ? _vm.syndromeNameTech
                                                                    : _vm.syndromeName
                                                                )
                                                              )
                                                            ]),
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-chevron-down ml-2"
                                                            })
                                                          ]
                                                        )
                                                      : _c("small", [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "vue.filter.syndrome.select_sub_category"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-chevron-down ml-2"
                                                          })
                                                        ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        _vm._l(
                                          _vm.subSyndromes(mainSyndrome.id),
                                          function(subSyndrome) {
                                            return _c(
                                              "b-dropdown-item",
                                              {
                                                key: subSyndrome.id,
                                                attrs: {
                                                  "link-class": "py-2 px-3",
                                                  active:
                                                    _vm.syndromeId ==
                                                    subSyndrome.id
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.selectSubSyndrome(
                                                      subSyndrome
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.syndromeNameOrText(
                                                        subSyndrome.name,
                                                        subSyndrome.term
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }