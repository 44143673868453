var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "autocomplete" }, [
    _c(
      "div",
      {
        staticClass: "autocomplete__box",
        class: { autocomplete__searching: _vm.showResults }
      },
      [
        _c("div", { staticClass: "autocomplete__inputs" }, [
          _c("input", {
            class: _vm.inputClass,
            attrs: {
              placeholder: _vm.placeholder,
              disabled: _vm.disableInput,
              maxlength: _vm.maxlength,
              type: "text",
              autocomplete: "off"
            },
            domProps: { value: _vm.display },
            on: {
              input: [
                function($event) {
                  _vm.display = $event.target.value
                },
                _vm.search
              ],
              click: _vm.search,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.enter($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  return _vm.space($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  ) {
                    return null
                  }
                  return _vm.close($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  return _vm.up($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  return _vm.down($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  return _vm.close($event)
                }
              ],
              focus: _vm.focus,
              blur: _vm.blur
            }
          }),
          _c("input", {
            attrs: { name: _vm.name, type: "hidden" },
            domProps: { value: _vm.value }
          })
        ]),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.disableInput &&
                  !_vm.isEmpty &&
                  !_vm.isLoading &&
                  !_vm.hasError,
                expression:
                  "!disableInput && !isEmpty && !isLoading && !hasError"
              }
            ],
            staticClass: "autocomplete__icon autocomplete--clear",
            on: { click: _vm.clear }
          },
          [
            _vm.clearButtonIcon
              ? _c("span", { class: _vm.clearButtonIcon })
              : _c("span", [_vm._v("×")])
          ]
        )
      ]
    ),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showResults,
            expression: "showResults"
          }
        ],
        staticClass: "autocomplete__results",
        style: _vm.listStyle
      },
      [
        _vm._t("results", [
          _vm.hasError
            ? _c(
                "li",
                {
                  staticClass:
                    "autocomplete__results__item autocomplete__results__item--error"
                },
                [_vm._v(_vm._s(_vm.error))]
              )
            : _vm._e(),
          !_vm.hasError
            ? [
                _vm._t("firstResult"),
                _vm._l(_vm.results, function(result, key) {
                  return _c("li", {
                    key: key,
                    staticClass: "autocomplete__results__item",
                    class: { autocomplete__selected: _vm.isSelected(key) },
                    domProps: { innerHTML: _vm._s(_vm.formatDisplay(result)) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.select(result)
                      }
                    }
                  })
                }),
                _vm._t("lastResult")
              ]
            : _vm._e(),
          _vm.noResultMessage
            ? _c(
                "li",
                {
                  staticClass:
                    "autocomplete__results__item autocomplete__no-results"
                },
                [_vm._t("noResults", [_vm._v("No Results.")])],
                2
              )
            : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }