<template>
    <div class="bg-light d-print-none">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-8">
                    <div class="my-4">
                        <autocomplete
                            id="searchInputComponent"
                            name="searchInputComponent"
                            ref="autocomplete"
                            input-class="form-control form-control-lg autocomplete-main-input"
                            :results-display="formattedDisplay"
                            :source="searchUrl"
                            results-property="Object"
                            v-bind:placeholder="$t('vue.search.placeholder')"
                            results-value="id"
                            clearButtonIcon="sf-icon sf-ic-nav-close"
                            v-bind:showNoResults="false"
                            v-on:selected="search_selected"
                            v-on:space="gaHandlerSpace"
                            v-on:nothingSelected="search_nothingSelected"
                            v-bind:aria-label="$t('vue.search.placeholder')"
                        />
                        <div id="search-error-toast-container">
                            <b-toast id="search-error-toast" static solid>
                                <template #toast-title>
                                    {{ $t('toast.default.title') }}
                                </template>
                                {{ $t('vue.autocomplete.no_results') }}
                            </b-toast>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Autocomplete from '@/components/AutoComplete'

export default {
    components: {
        Autocomplete
    },
    mounted() {
        document.getElementsByClassName("autocomplete-main-input")[0].setAttribute("aria-label", this.$t('vue.search.placeholder'));
    },
    methods: {
        checkInput(input) {
            var inputAsParam = input.replace("#", " ");
            return inputAsParam
        },
        gaHandlerSpace(query) {
            this.$gtag.event('Search', {
                'event_category': 'space',
                'event_label': query
            });
        },
        searchUrl(input) {
            if (this.$route.name && this.$route.name.indexOf("Spital") > -1 && this.$store.state.hospital.categoryId === "akut") {
                return `/api/search-suggestions.json?q=${this.checkInput(input)}&hospital=${this.$store.state.hospital.urlKey}`;
            }
            return `/api/search-suggestions.json?q=${input}`;
        },
        formattedDisplay (result) {
            return result.highlighted;
        },
        search_selected: function (object) {
            if(object) {
                this.$refs.autocomplete.display = object.selectedObject.text;
                this.$refs.autocomplete.selectedDisplay = object.selectedObject.text;
                if (this.$route.fullPath != object.selectedObject.href) {
                    if (object.selectedObject.entityClass=="syndrome" && !(this.$route.name && this.$route.name.indexOf("Spital") > -1)){
                        this.$router.push(this.$store.getters.getHospitalsUrl({
                          categoryUrlKey: object.selectedObject.href.split("/")[1],
                          syndromeUrlKey: object.selectedObject.href.split("/")[2],
                          sort: null,
                          pageNum: null
                        }));
                    } else {
                        this.$router.push(object.selectedObject.href);
                    }
                }
            }
        },
        search_nothingSelected: function (payload) {
            if(payload) {
                this.$gtag.event('Search', {
                    'event_category': 'enter',
                    'event_label': payload.trim()
                });
                if(!this.$refs.autocomplete.noResults) {
                    this.$refs.autocomplete.select(this.$refs.autocomplete.results[0])
                    document.getElementsByClassName("autocomplete-main-input")[0].blur();
                } else {
                    //this.$toast(this.$t('vue.autocomplete.no_results'), {toaster: 'b-toaster-top-center'})
                    this.$bvToast.show("search-error-toast")
                }
            } else {
                console.log('autocomplete enter nothingSelected !')
            }
        }
    }
}
</script>
