<template>
    <footer class="main-footer">
        <div class="container py-4">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="footer-logo mr-4 d-inline-block">
                        <a href="https://www.santesuisse.ch/" title="santésuisse Website">
                            <img src="../assets/santesuisse_logo.png" alt="santésuisse Logo" height="30" width="93" class="img-fluid">
                        </a>
                    </div>
                    <div class="footer-logo d-inline-block">
                        <a href="https://www.konsum.ch/" title="Schweizerisches Konsumentenforum kf Website">
                            <img src="../assets/logo-kf.png" alt="Schweizerisches Konsumentenforum kf Website" height="30" width="121" class="img-fluid">
                        </a>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-md-end">
                    <ul class="list-unstyled footer-cms-links mb-0 mt-4 mt-md-0">
                        <li class="nav-item" v-for="link in footerLinks" :key="link.id">
                            <a :href="link.url" :title="link.label">
                                {{ link.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        created() {
            this.getFooterLinks();
        },
        computed: {
            ...mapGetters(['footerLinks'])
        },
        methods: {
            ...mapActions(['getFooterLinks'])
        }
    }
</script>