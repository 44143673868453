<template>
  <div class="autocomplete">
    <div class="autocomplete__box" :class="{'autocomplete__searching' : showResults}">
      <div class="autocomplete__inputs">
        <input
          v-bind:value="display"
          v-on:input="display=$event.target.value"
          :placeholder="placeholder"
          :disabled="disableInput"
          :maxlength="maxlength"
          :class="inputClass"
          @click="search"
          @input="search"
          @keydown.enter="enter"
          @keydown.space="space"
          @keydown.tab="close"
          @keydown.up="up"
          @keydown.down="down"
          @keydown.esc="close"
          @focus="focus"
          @blur="blur"
          type="text"
          autocomplete="off">
        <input :name="name" type="hidden" :value="value">
      </div>

      <!-- clearButtonIcon -->
      <span v-show="!disableInput && !isEmpty && !isLoading && !hasError" class="autocomplete__icon autocomplete--clear" @click="clear">
        <span v-if="clearButtonIcon" :class="clearButtonIcon"></span>
        <span v-else>&times;</span>
      </span>
    </div>

    <ul v-show="showResults" class="autocomplete__results" :style="listStyle">
      <slot name="results">
        <!-- error -->
        <li v-if="hasError" class="autocomplete__results__item autocomplete__results__item--error">{{ error }}</li>

        <!-- results -->
        <template v-if="!hasError">
          <slot name="firstResult"></slot>
          <li
              v-for="(result, key) in results"
              :key="key"
              @click.prevent="select(result)"
              class="autocomplete__results__item"
              :class="{'autocomplete__selected' : isSelected(key) }"
              v-html="formatDisplay(result)">
          </li>
          <slot name="lastResult"></slot>
        </template>

        <!-- no results -->
        <li v-if="noResultMessage" class="autocomplete__results__item autocomplete__no-results">
          <slot name="noResults">No Results.</slot>
        </li>
      </slot>
    </ul>
  </div>
</template>

<script>
import Autocomplete from 'vuejs-auto-complete'
export default {
  extends: Autocomplete,
  methods: {
    focus() {
      if (this.value) {
        this.clear()
      }
      this.isFocussed = true
    },
    space() {
      this.$emit('space', this.display)
    }
  }
}
</script>