<template>
    <div class="d-none d-print-block">
        <div class="container-fluid mb-4">
            {{ baseUrl }}{{ $route.fullPath }}
        </div>
        <div class="d-flex justify-content-center py-2 border-bottom">
            <Logo/>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo'

export default {
    components: {
        Logo
    },
    computed: {
        baseUrl() {
            return location.origin
        }
    }
}
</script>