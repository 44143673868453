export default {
  install (Vue) {
    Vue.filter('formatNumber', function (value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    });
    Vue.prototype.$formatNumber = function (number) {
      if (!number) return '';
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }
  }
}