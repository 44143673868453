import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export function createStore() {
    return new Vuex.Store({

        state: {
            hospital: false,
            syndrome: {},
            hospitals: false,
            hospitalCategories: [],
            syndromeCategoryList: [],
            syndromeList: [],
            termSwitchOn: false,
            isUsingLocation: false,
            languageSwitcherUrls: {},
            navigationItems: [],
            privacyPolicyLink: {url:"#"},
            definitionLink: {url:"#"},
            definitions: {
                akut: {url:"#"},
                akutM: {url:"#"},
                akutP: {url:"#"},
                akutF: {url:"#"},
                reha: {url:"#"},
                psych: {url:"#"},
            },
            footerLinks: [],
            syndromeFilterOpen: false,
            filter: {
              categoryId: null,
              categoryUrlKey: null,
              locationId: null,
              lat: null,
              lon: null,
              locationName: null,
              range: null,
              syndromeId: null,
              syndromeUrlKey: null,
              syndromeName: null,
              syndromeNameTech: null
            },
            paginator: {
              page: null,
              totalPages: null,
              itemsPerPage: null,
              totalElements: null
            },
            sortingChosen: false,
            sort: null,
            syndromeId: "",
            syndromeName: "",
            locationId: "",
            locationName: "",
            lat: "",
            lon: "",
            range: ""
        },
        getters: {
            getSyndromeId(state) {
                return state.filter.syndromeId
            },
            getCategoryId(state) {
                return state.filter.categoryId
            },
            getCategoryUrlKey(state) {
                return state.filter.categoryUrlKey
            },
            getFilter(state) {
                return state.filter
            },
            getSorting(state) {
                return state.sort
            },
            getPaginator(state) {
                return state.paginator
            },
            getSyndromeCategoryList(state) {
                return state.syndromeCategoryList
            },
            getSyndromeList(state) {
                return state.syndromeList
            },
            hospitalCategories(state) {
                return state.hospitalCategories
            },
            navigationItems(state) {
                return state.navigationItems
            },
            footerLinks(state) {
                return state.footerLinks
            },
            privacyPolicyLink(state) {
                return state.privacyPolicyLink
            },
            definitionLinks(state) {
                return state.definitions
            },
            getSyndromeFilterOpen(state) {
                return state.syndromeFilterOpen
            },
            getHospitalsUrl: (state) => (config) => {
                const urlConf = {...state.filter, pageNum: state.paginator.page, sort: state.sort, ...config};
                const {
                    categoryUrlKey,
                    syndromeUrlKey,
                    locationId,
                    lat,
                    lon,
                    range,
                    query,
                    pageNum,
                    sort,
                } = urlConf;

                const e_lat = locationId ? null : lat;
                const e_lon = locationId ? null : lon;

                const queryParams = {
                    locationId: locationId,
                    lat: e_lat,
                    lon: e_lon,
                    range: range,
                    query: query,
                    page: pageNum,
                    sort: sort
                };

                if (syndromeUrlKey) {
                    return jsRoutes.ch.santesuisse.spitalfinder.controller.VueController.hospitalCategoryWithSyndrome(
                        categoryUrlKey,
                        syndromeUrlKey,
                        queryParams
                    ).url
                } else {
                    return jsRoutes.ch.santesuisse.spitalfinder.controller.VueController.hospitalCategory(
                        categoryUrlKey,
                        queryParams
                    ).url
                }
            },
            getHospitalUrl: (state) => (config) => {
                const urlConf = {...config};
                const {
                    lang,
                    hospitalUrlKey,
                    syndromeUrlKey,
                    year,
                } = urlConf;

                const queryParams = {year: year}

                if (syndromeUrlKey) {
                    return jsRoutes.ch.santesuisse.spitalfinder.controller.VueController.hospitalDetailWithSyndrome(
                        lang,
                        hospitalUrlKey,
                        syndromeUrlKey,
                        queryParams
                    ).url
                } else {
                    return jsRoutes.ch.santesuisse.spitalfinder.controller.VueController.hospitalDetail(
                        lang,
                        hospitalUrlKey,
                        queryParams
                    ).url
                }
            },
            languageSwitcherUrls(state) {
                return state.languageSwitcherUrls
            },
        },
        mutations: {
            setHospital(state, {hospital}) {
                state.hospital = hospital
            },
            setHospitals(state, {hospitals}) {
                state.hospitals = hospitals
            },
            setHospitalCategories(state, {hospitalCategories}) {
                state.hospitalCategories = hospitalCategories
            },
            setSyndromeCategoryList(state, {syndromeCategoryList}) {
                state.syndromeCategoryList = syndromeCategoryList
            },
            setSyndromeList(state, {syndromeList}) {
                state.syndromeList = syndromeList
            },
            setPaginator(state, {paginator}) {
                state.paginator = paginator
            },
            setLanguageSwitcherUrls(state, {languageSwitcherUrls}) {
                state.languageSwitcherUrls = languageSwitcherUrls
            },
            setFilter(state, {filter}) {
                state.filter = filter
            },
            setSorting(state, {sort}) {
                state.sort = sort
            },
            setNavigationItems(state, {navigationItems}) {
                state.navigationItems = navigationItems
            },
            setFooterLinks(state, {footerLinks}) {
                state.footerLinks = footerLinks
            },
            setPrivacyPolicyLink(state, {privacyPolicyLink}) {
                state.privacyPolicyLink = privacyPolicyLink
            },
            setDefinitionLinks(state, definition) {
                state.definitions = {...state.definitions, ...definition}
            },
            getSyndromeFilterOpen(state, {syndromeFilterOpen}) {
                state.syndromeFilterOpen = syndromeFilterOpen
            },
            resetHospitalList(state) {
                state.filter = {
                    categoryId: null,
                    categoryUrlKey: null,
                    locationId: null,
                    lat: null,
                    lon: null,
                    locationName: null,
                    range: null,
                    syndromeId: null,
                    syndromeUrlKey: null,
                    syndromeName: null,
                    syndromeNameTech: null
                }
                state.hospitals = false
            }
        },
        actions: {
            getHospitalCategories ({ commit }) {
                return axios.get('/api/hospital-categories').then(response => {
                    const hospitalCategories = response.data;
                    commit('setHospitalCategories', {hospitalCategories});
                }).catch(error => {
                    commit('setHospitalCategories', {hospitalCategories: []});
                });
            },
            getHospitals ({ commit }, apiUrl) {
                return axios.get(`/api${apiUrl}`).then(response => {
                    const {
                        hospitals,
                        paginator,
                        filter,
                        sort,
                        languageSwitcherUrls
                    } = response.data;
                    commit('setHospitals', {hospitals});
                    commit('setPaginator', {paginator});
                    commit('setFilter', {filter});
                    commit('setSorting', {sort});
                    commit('setLanguageSwitcherUrls', {languageSwitcherUrls});
                }).catch(error => {
                    commit('setHospitals', {hospitals: []});
                });
            },
            getHospital ({ commit }, apiUrl) {
                return axios.get(`/api${apiUrl}`).then(response => {
                    const {
                        hospital,
                        languageSwitcherUrls
                    } = response.data;
                    commit('setHospital', {hospital});
                    commit('setLanguageSwitcherUrls', {languageSwitcherUrls});
                }).catch(error => {
                    commit('setHospital', {hospital: {}});
                });
            },
            getSyndromes ({ commit }) {
                return axios.get(`/api/syndromes`).then(response => {
                    const {
                        syndromeCategoryList,
                        syndromeList
                    } = response.data;
                    commit('setSyndromeCategoryList', {syndromeCategoryList});
                    commit('setSyndromeList', {syndromeList});
                }).catch(error => {
                    commit('setSyndromeCategoryList', {syndromeCategoryList: []});
                    commit('setSyndromeList', {syndromeList: []});
                });
            },
            getNavigationItems ({ commit }) {
                return axios.get(`/api/navigation-items/_headerLinks`).then(response => {
                    commit('setNavigationItems', {navigationItems: response.data});
                }).catch(error => {
                    commit('setNavigationItems', {navigationItems: []});
                });
            },
            getFooterLinks ({ commit }) {
                return axios.get(`/api/navigation-items/_footerLinks`).then(response => {
                    commit('setFooterLinks', {footerLinks: response.data});
                }).catch(error => {
                    commit('setFooterLinks', {footerLinks: []});
                });
            },
            getPrivacyPolicyLink ({ commit }) {
                return axios.get(`/api/navigation-item/_privacyPolicy`).then(response => {
                    commit('setPrivacyPolicyLink', {privacyPolicyLink: response.data.url ? response.data : "#"});
                }).catch(error => {
                    commit('setPrivacyPolicyLink', {privacyPolicyLink: "#"});
                });
            },
            getDefinitionLinkAkut ({ commit }) {
                return axios.get(`/api/navigation-item/_definition_akut`).then(response => {
                    commit('setDefinitionLinks', {akut: response.data.url ? response.data : {url: "#"}});
                }).catch(error => {
                    commit('setDefinitionLinks', {akut: {url: "#"}});
                });
            },
            getDefinitionLinkAkutM ({ commit }) {
                return axios.get(`/api/navigation-item/_definition_akutM`).then(response => {
                    commit('setDefinitionLinks', {akutM: response.data.url ? response.data : {url: "#"}});
                }).catch(error => {
                    commit('setDefinitionLinks', {akutM: {url: "#"}});
                });
            },
            getDefinitionLinkAkutP ({ commit }) {
                return axios.get(`/api/navigation-item/_definition_akutP`).then(response => {
                    commit('setDefinitionLinks', {akutP: response.data.url ? response.data : {url: "#"}});
                }).catch(error => {
                    commit('setDefinitionLinks', {akutP: {url: "#"}});
                });
            },
            getDefinitionLinkAkutF ({ commit }) {
                return axios.get(`/api/navigation-item/_definition_akutF`).then(response => {
                    commit('setDefinitionLinks', {akutF: response.data.url ? response.data : {url: "#"}});
                }).catch(error => {
                    commit('setDefinitionLinks', {akutF: {url: "#"}});
                });
            },
            getDefinitionLinkReha ({ commit }) {
                return axios.get(`/api/navigation-item/_definition_reha`).then(response => {
                    commit('setDefinitionLinks', {reha: response.data.url ? response.data : {url: "#"}});
                }).catch(error => {
                    commit('setDefinitionLinks', {reha: {url: "#"}});
                });
            },
            getDefinitionLinkPsych ({ commit }) {
                return axios.get(`/api/navigation-item/_definition_psych`).then(response => {
                    commit('setDefinitionLinks', {psych: response.data.url ? response.data : {url: "#"}});
                }).catch(error => {
                    commit('setDefinitionLinks', {psych: {url: "#"}});
                });
            },
            getLanguageUrls ({ commit }, apiUrl) {
                return axios.get(`/api${apiUrl}`).then(response => {
                    const {
                        languageSwitcherUrls
                    } = response.data;
                    commit('setLanguageSwitcherUrls', {languageSwitcherUrls});
                }).catch(error => {
                    commit('setLanguageSwitcherUrls', {languageSwitcherUrls: {}});
                });
            },
        }
    })
}
