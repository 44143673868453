var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("Search"),
      _c("ClinicTypeLinks"),
      _c("ClinicToolbar"),
      _c("PrintHeaderList"),
      _c("div", { staticClass: "container mt-4 d-print-none" }, [
        !_vm.loading && _vm.paginator.totalPages
          ? _c(
              "div",
              { staticClass: "row justify-content-center" },
              [
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.paginator.totalPages,
                    "page-range": 3,
                    "margin-pages": 2,
                    "click-handler": _vm.changePage,
                    "prev-text": _vm.$t("vue.paginate.prev"),
                    "next-text": _vm.$t("vue.paginate.next"),
                    "container-class": "pagination",
                    "page-class": "page-item",
                    "page-link-class": "page-link",
                    "prev-link-class": "page-link",
                    "next-link-class": "page-link"
                  },
                  model: {
                    value: _vm.paginator.page,
                    callback: function($$v) {
                      _vm.$set(_vm.paginator, "page", $$v)
                    },
                    expression: "paginator.page"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.loading
        ? _c("div", { staticClass: "container-fluid" }, [
            _c(
              "div",
              { staticClass: "py-5 d-flex justify-content-center" },
              [
                _c("FaIcon", {
                  attrs: {
                    icon: "circle-notch",
                    iclass: "fa-spin fa-3x text-primary"
                  }
                })
              ],
              1
            )
          ])
        : !_vm.loading && _vm.hospitals.length > 0
        ? _c("div", { staticClass: "clinic-list position-relative py-3" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.hospitals, function(hospital) {
                  return _c(
                    "div",
                    {
                      key: hospital.id,
                      staticClass: "col-12 col-lg-6 col-xl-4 py-3 clinic-col"
                    },
                    [_c("HospitalBox", { attrs: { hospital: hospital } })],
                    1
                  )
                }),
                0
              )
            ]),
            _vm.loadingPage
              ? _c("div", { staticClass: "loading-overlay" }, [
                  _c(
                    "div",
                    { staticClass: "py-5 my-5 d-flex justify-content-center" },
                    [
                      _c("FaIcon", {
                        attrs: {
                          icon: "circle-notch",
                          iclass: "fa-spin fa-3x text-primary"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.hospitals.length == 0 &&
      _vm.filter.range !== null &&
      !_vm.loading &&
      !_vm.loadingPage
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("b-alert", { attrs: { variant: "warning", show: "" } }, [
                _vm._v(
                  _vm._s(_vm.$t("vue.hospitals.not_found.with_region_filter"))
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.hospitals.length == 0 &&
      _vm.filter.range == null &&
      !_vm.loading &&
      !_vm.loadingPage
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("b-alert", { attrs: { variant: "warning", show: "" } }, [
                _vm._v(_vm._s(_vm.$t("vue.hospitals.not_found")))
              ])
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "container d-print-none mb-5" }, [
        !_vm.loading && _vm.paginator.totalPages
          ? _c(
              "div",
              { staticClass: "row justify-content-center" },
              [
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.paginator.totalPages,
                    "page-range": 3,
                    "margin-pages": 2,
                    "click-handler": _vm.changePage,
                    "prev-text": _vm.$t("vue.paginate.prev"),
                    "next-text": _vm.$t("vue.paginate.next"),
                    "container-class": "pagination",
                    "page-class": "page-item",
                    "page-link-class": "page-link",
                    "prev-link-class": "page-link",
                    "next-link-class": "page-link"
                  },
                  model: {
                    value: _vm.paginator.page,
                    callback: function($$v) {
                      _vm.$set(_vm.paginator, "page", $$v)
                    },
                    expression: "paginator.page"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }