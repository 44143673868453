var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "syndrom-filter-wrapper d-flex justify-content-center order-0 order-lg-2 mb-3 mb-lg-0"
    },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "syndrome_filter_collapse",
              expression: "'syndrome_filter_collapse'"
            }
          ],
          class:
            "d-flex py-3 px-4 align-items-center text-body " +
            (_vm.syndromeSelected ? "active" : "") +
            " flex-grow-1 flex-lg-grow-0 collapsed",
          attrs: {
            id: "syndrom-filter-toggle",
            role: "button",
            title:
              (_vm.$store.state.termSwitchOn
                ? _vm.syndromeNameTech
                : _vm.syndromeName) ||
              _vm.$t("vue.filter.syndrome.no_selection")
          }
        },
        [
          _c("SpfrIcon", {
            attrs: { icon: "diagnosis-other", iclass: "icon-big" }
          }),
          _c("span", { staticClass: "d-flex flex-column ml-3" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("vue.filter.syndrome.diagnose")))
            ]),
            _vm.syndromeSelected
              ? _c("span", { staticClass: "filter-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$store.state.termSwitchOn
                        ? _vm.syndromeNameTech
                        : _vm.syndromeName
                    )
                  )
                ])
              : _c("span", { staticClass: "filter-value" }, [
                  _vm._v(_vm._s(_vm.$t("vue.filter.syndrome.no_selection")))
                ])
          ])
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary px-3 filter-reset",
          attrs: {
            type: "button",
            "aria-label": _vm.$t("filter.syndrome.reset")
          },
          on: { click: _vm.syndrome_filter_reset }
        },
        [_c("SpfrIcon", { attrs: { icon: "nav-close" } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }