export default {
  install (Vue) {
    Vue.prototype.$toast = function (msg, options) {
      const defaultOptions = {
        title: this.$t('toast.default.title'),
        toaster: 'b-toaster-top-right',
        autoHideDelay: 5000,
        appendToast: false
      }
      this.$bvToast.toast(msg, Object.assign(defaultOptions, options))
    }
  }
}