export default {
    computed: {
        canUseGroupValue() {
            return true
        },
        showSyndromeStats() {
            if (this.hospital.syndromeStats) {
                if (!this.hospital.syndromeStats.syndromeCode) {
                    return false
                } else if (this.hospital.categoryId === 'akut') {
                    return this.hospital.syndromeStats
                } else {
                    return this.hospital.syndromeStats.caseNumbers
                }
            } else {
                return false;
            }
        },
        syndromeStats() {
            return this.hospital.syndromeStats
        },
        hospitalStats() {
            return this.hospital.hospitalStats
        },
        hospitalPatientSatisfaction() {
            if (this.hospitalStats.patientSatisfaction !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.patientSatisfactionHasStats,
                    value: this.hospitalStats.patientSatisfaction,
                    flags: this.hospitalStats.patientSatisfactionFlags,
                    year: this.hospitalStats.patientSatisfactionYear
                }
            } else if (this.hospitalStats.patientSatisfactionGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.patientSatisfactionHasStats,
                    value: this.hospitalStats.patientSatisfactionGroup,
                    flags: this.hospitalStats.patientSatisfactionGroupFlags,
                    year: this.hospitalStats.patientSatisfactionGroupYear
                }
            } else if (this.hospitalStats.patientSatisfactionOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.patientSatisfactionHasStats,
                    value: this.hospitalStats.patientSatisfactionOverallGroup,
                    flags: this.hospitalStats.patientSatisfactionOverallGroupFlags,
                    year: this.hospitalStats.patientSatisfactionOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.patientSatisfactionHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalWoundInfections() {
            if (this.hospitalStats.woundInfections !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: this.hospitalStats.woundInfections,
                    flags: this.hospitalStats.woundInfectionsFlags,
                    year: this.hospitalStats.woundInfectionsYear
                }
            } else if (this.hospitalStats.woundInfectionsGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: this.hospitalStats.woundInfectionsGroup,
                    flags: this.hospitalStats.woundInfectionsGroupFlags,
                    year: this.hospitalStats.woundInfectionsGroupYear
                }
            } else if (this.hospitalStats.woundInfectionsOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: this.hospitalStats.woundInfectionsOverallGroup,
                    flags: this.hospitalStats.woundInfectionsOverallGroupFlags,
                    year: this.hospitalStats.woundInfectionsOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalPressureSores() {
            if (this.hospitalStats.pressureSores !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.pressureSoresHasStats,
                    value: this.hospitalStats.pressureSores,
                    flags: this.hospitalStats.pressureSoresFlags,
                    year: this.hospitalStats.pressureSoresYear
                }
            } else if (this.hospitalStats.pressureSoresGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.pressureSoresHasStats,
                    value: this.hospitalStats.pressureSoresGroup,
                    flags: this.hospitalStats.pressureSoresGroupFlags,
                    year: this.hospitalStats.pressureSoresGroupYear
                }
            } else if (this.hospitalStats.pressureSoresOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.pressureSoresHasStats,
                    value: this.hospitalStats.pressureSoresOverallGroup,
                    flags: this.hospitalStats.pressureSoresOverallGroupFlags,
                    year: this.hospitalStats.pressureSoresOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.pressureSoresHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalRateRenewalImplantat() {
            if (this.hospitalStats.rateRenewalImplantat !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: this.hospitalStats.rateRenewalImplantat,
                    flags: this.hospitalStats.rateRenewalImplantatFlags,
                    year: this.hospitalStats.rateRenewalImplantatYear
                }
            } else if (this.hospitalStats.rateRenewalImplantatGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: this.hospitalStats.rateRenewalImplantatGroup,
                    flags: this.hospitalStats.rateRenewalImplantatGroupFlags,
                    year: this.hospitalStats.rateRenewalImplantatGroupYear
                }
            } else if (this.hospitalStats.rateRenewalImplantatOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: this.hospitalStats.rateRenewalImplantatOverallGroup,
                    flags: this.hospitalStats.rateRenewalImplantatOverallGroupFlags,
                    year: this.hospitalStats.rateRenewalImplantatOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalSymptomBurdens() {
            if (this.hospitalStats.symptomBurdens !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.symptomBurdensHasStats,
                    value: this.hospitalStats.symptomBurdens,
                    flags: this.hospitalStats.symptomBurdensFlags,
                    year: this.hospitalStats.symptomBurdensYear
                }
            } else if (this.hospitalStats.symptomBurdensGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.symptomBurdensHasStats,
                    value: this.hospitalStats.symptomBurdensGroup,
                    flags: this.hospitalStats.symptomBurdensGroupFlags,
                    year: this.hospitalStats.symptomBurdensGroupYear
                }
            } else if (this.hospitalStats.symptomBurdensOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.symptomBurdensHasStats,
                    value: this.hospitalStats.symptomBurdensOverallGroup,
                    flags: this.hospitalStats.symptomBurdensOverallGroupFlags,
                    year: this.hospitalStats.symptomBurdensOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.symptomBurdensHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalRehaSuccess() {
            if (this.hospitalStats.rehaSuccess !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.rehaSuccessHasStats,
                    value: this.hospitalStats.rehaSuccess,
                    flags: this.hospitalStats.rehaSuccessFlags,
                    year: this.hospitalStats.rehaSuccessYear
                }
            } else if (this.hospitalStats.rehaSuccessGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.rehaSuccessHasStats,
                    value: this.hospitalStats.rehaSuccessGroup,
                    flags: this.hospitalStats.rehaSuccessGroupFlags,
                    year: this.hospitalStats.rehaSuccessGroupYear
                }
            } else if (this.hospitalStats.rehaSuccessOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.rehaSuccessHasStats,
                    value: this.hospitalStats.rehaSuccessOverallGroup,
                    flags: this.hospitalStats.rehaSuccessOverallGroupFlags,
                    year: this.hospitalStats.rehaSuccessOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.rehaSuccessHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalFalls() {
            if (this.hospitalStats.falls !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.fallsHasStats,
                    value: this.hospitalStats.falls,
                    year: this.hospitalStats.fallsYear
                }
            } else if (this.hospitalStats.fallsGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.fallsHasStats,
                    value: this.hospitalStats.fallsGroup,
                    year: this.hospitalStats.fallsGroupYear
                }
            } else if (this.hospitalStats.fallsOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.fallsHasStats,
                    value: this.hospitalStats.fallsOverallGroup,
                    year: this.hospitalStats.fallsOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.fallsHasStats,
                    value: null,
                    year: null
                }
            }
        },
        hospitalRehospitalization() {
            if (this.hospitalStats.rehospitalization !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.rehospitalizationHasStats,
                    value: this.hospitalStats.rehospitalization,
                    year: this.hospitalStats.rehospitalizationYear
                }
            } else if (this.hospitalStats.rehospitalizationGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.rehospitalizationHasStats,
                    value: this.hospitalStats.rehospitalizationGroup,
                    year: this.hospitalStats.rehospitalizationGroupYear
                }
            } else if (this.hospitalStats.rehospitalizationOverallGroup !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.rehospitalizationHasStats,
                    value: this.hospitalStats.rehospitalizationOverallGroup,
                    year: this.hospitalStats.rehospitalizationOverallGroupYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.rehospitalizationHasStats,
                    value: null,
                    year: null
                }
            }
        },
        syndromeCaseNumbers() {
            if (this.syndromeStats.caseNumbers !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.caseNumbersHasStats,
                    value: this.syndromeStats.caseNumbers,
                    year: this.syndromeStats.caseNumbersYear
                }
            } else if (this.syndromeStats.groupCaseNumbers !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.caseNumbersHasStats,
                    value: this.syndromeStats.groupCaseNumbers,
                    year: this.syndromeStats.groupCaseNumbersYear
                }
            } else if (this.syndromeStats.overallGroupCaseNumbers !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.caseNumbersHasStats,
                    value: this.syndromeStats.overallGroupCaseNumbers,
                    year: this.syndromeStats.overallGroupCaseNumbersYear
                }
            } else {
                return {
                    hasStats: this.hospitalStats.caseNumbersHasStats,
                    value: null,
                    year: null
                }
            }
        },
        mainSyndromeCaseNumbers() {
            if (this.syndromeStats.mainSyndromeCaseNumbers !== null) {
                return {
                    type: 'hospital',
                    mainSyndromeCaseNumbers: this.syndromeStats.mainSyndromeCaseNumbers,
                    mainSyndromeName: this.syndromeStats.mainSyndromeName,
                    mainSyndromeCaseNumbersYear: this.syndromeStats.mainSyndromeCaseNumbersYear,
                    syndromeName: this.hospital.syndromeStats.syndromeName,
                    percentage: this.hospital.syndromeStats.percentage
                }
            } else if (this.syndromeStats.groupMainSyndromeCaseNumbers !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    mainSyndromeCaseNumbers: this.syndromeStats.groupMainSyndromeCaseNumbers,
                    mainSyndromeName: this.syndromeStats.groupMainSyndromeName,
                    mainSyndromeCaseNumbersYear: this.syndromeStats.groupMainSyndromeCaseNumbersYear,
                    syndromeName: this.hospital.syndromeStats.syndromeName,
                    percentage: this.hospital.syndromeStats.groupPercentage
                }
            } else if (this.syndromeStats.overallGroupMainSyndromeCaseNumbers !== null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    mainSyndromeCaseNumbers: this.syndromeStats.overallGroupMainSyndromeCaseNumbers,
                    mainSyndromeName: this.syndromeStats.overallGroupMainSyndromeName,
                    mainSyndromeCaseNumbersYear: this.syndromeStats.overallGroupMainSyndromeCaseNumbersYear,
                    syndromeName: this.hospital.syndromeStats.syndromeName,
                    percentage: this.hospital.syndromeStats.overallGroupPercentage
                }
            } else {
                return {
                    mainSyndromeCaseNumbers: null,
                    mainSyndromeName: null,
                    mainSyndromeCaseNumbersYear: null,
                    syndromeName: null,
                    percentage: null
                }
            }
        },
        syndromeFlag() {
            if (this.syndromeStats.flag !== null) {
                return {
                    type: 'hospital',
                    value: this.syndromeStats.flag,
                    mortality: this.syndromeStats.mortality
                }
            } else if (this.syndromeStats.groupFlag !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    value: this.syndromeStats.groupFlag,
                    mortality: this.syndromeStats.groupMortality
                }
            } else if (this.syndromeStats.overallGroupFlag != null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    value: this.syndromeStats.overallGroupFlag,
                    mortality: this.syndromeStats.overallGroupMortality
                }
            } else {
                return {
                    value: null,
                    mortality: null
                }
            }
        },
        syndromeWoundInfections() {
            if (this.syndromeStats.woundInfections !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: this.syndromeStats.woundInfections,
                    year: this.syndromeStats.woundInfectionsYear,
                    flags: this.syndromeStats.woundInfectionsFlags
                }
            } else if (this.syndromeStats.groupWoundInfections !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: this.syndromeStats.groupWoundInfections,
                    year: this.syndromeStats.groupWoundInfectionsYear,
                    flags: this.syndromeStats.groupWoundInfectionsFlags
                }
            } else if (this.syndromeStats.overallGroupWoundInfections != null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: this.syndromeStats.overallGroupWoundInfections,
                    year: this.syndromeStats.overallGroupWoundInfectionsYear,
                    flags: this.syndromeStats.overallGroupWoundInfectionsFlags
                }
            } else {
                return {
                    hasStats: this.hospitalStats.woundInfectionsHasStats,
                    value: null,
                    flags: null
                }
            }
        },
        syndromeRateRenewalImplantat() {
            if (this.syndromeStats.rateRenewalImplantat !== null) {
                return {
                    type: 'hospital',
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: this.syndromeStats.rateRenewalImplantat,
                    year: this.syndromeStats.rateRenewalImplantatYear,
                    flags: this.syndromeStats.rateRenewalImplantatFlags
                }
            } else if (this.syndromeStats.groupRateRenewalImplantat !== null && this.canUseGroupValue) {
                return {
                    type: 'group',
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: this.syndromeStats.groupRateRenewalImplantat,
                    year: this.syndromeStats.groupRateRenewalImplantatYear,
                    flags: this.syndromeStats.groupRateRenewalImplantatFlags
                }
            } else if (this.syndromeStats.overallGroupRateRenewalImplantat != null && this.canUseGroupValue) {
                return {
                    type: 'overallGroup',
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: this.syndromeStats.overallGroupRateRenewalImplantat,
                    year: this.syndromeStats.overallGroupRateRenewalImplantatYear,
                    flags: this.syndromeStats.overallGroupRateRenewalImplantatFlags
                }
            } else {
                return {
                    hasStats: this.hospitalStats.rateRenewalImplantatHasStats,
                    value: null,
                    flags: null
                }
            }
        },
    }
}