<template>
    <i aria-hidden="true" :class="'sf-icon sf-ic-' + icon + (iclass ? ' ' + iclass : '')"></i>
</template>

<script>
export default {
  name: 'SpfrIcon',
  props: {
    icon: String,
    iclass: String
  }
}
</script>
