<template>
  <div>
    <Search/>
    <PrintHeaderSimple/>
    <div v-if="!loading && hospital && hospital.name">
      <div class="container-fluid clinic-detail-info py-3">
        <div class="d-flex justify-content-between d-print-none">
          <a @click.prevent="goBack()" href="#">
            <FaIcon icon="chevron-left" iclass="mr-1"/>
            {{ $t('vue.btn.back_to_search') }}
          </a>
          <a href="#" @click.prevent="copyToClipboard()" class="share-link" title="Link Teilen">
            <i class="sf-icon sf-share" aria-hidden="true"></i>
          </a>
        </div>
        <div class="bg-light mt-3 p-4">
          <div class="row">
            <div class="col-xl-9">
              <h1>{{ hospital.name }}</h1>
              <p class="h2" v-if="groupName">
                {{ $t('vue.hospital.group', {name: groupName}) }}
              </p>
              <div class="row clinic-detail-info-main">
                <div class="col-md-6">
                  <p class="text-muted" v-if="hospital.categoryId === 'reha'">
                    {{ hospital.categoryName }}
                  </p>
                  <p class="text-muted" v-else>
                    {{ hospital.categoryName }} - {{ hospital.typeName }}
                  </p>
                  <p>
                    <HospitalRating
                      :placeId="hospital.googlePlaceId"
                      :groupPlaceId="hospital.googlePlaceIdGroup"
                      :overallGroupPlaceId="hospital.googlePlaceIdOverallGroup"
                      :lat="hospital.lat"
                      :lng="hospital.lng"
                    />
                  </p>
                  <address>
                    {{ hospital.name }}<br>
                    {{ hospital.address }}<br>
                    {{ hospital.zip }} {{ hospital.city }}
                  </address>
                  <ul class="list-unstyled pt-2">
                    <li class="mb-2" v-if="hospital.lng && hospital.lat">
                      <a rel="noreferrer" :href="`https://maps.google.com/?q=${hospital.lat},${hospital.lng}`" target="_blank">
                        <i class="fas fa-map-marker-alt mr-1"></i>
                        {{ $t('vue.hospital.google_maps') }}
                      </a>
                    </li>
                    <li class="mb-2" v-if="hospital.website">
                      <a rel="noreferrer" :href="hospital.website" target="_blank">
                        <i class="sf-icon sf-ic-share mr-1"></i>
                        {{ $t('vue.hospital.website') }}
                      </a>
                    </li>
                    <li class="mb-2" v-if="hospital.phone">
                      <a :href="`tel:${hospital.phone}`">
                        <i class="sf-icon sf-ic-phone mr-1"></i>
                        {{ hospital.phone }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <div class="stat-source d-flex mt-5 mt-md-0">
                    <b-dropdown variant="link" toggle-tag="a" toggle-class="p-0">
                      <template v-slot:button-content>
                        {{ hospital.hospitalStats.year ? $t('vue.hospital.general_stats_year', {year: hospital.hospitalStats.year}) : $t('vue.hospital.latest_year') }}
                      </template>
                      <b-dropdown-item
                        :active="null == hospital.hospitalStats.year"
                        @click="changeYear(null)"
                      >
                        {{ $t('vue.hospital.latest_year_entry') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="availableYear in hospital.hospitalStats.availableYears"
                        :active="availableYear == hospital.hospitalStats.year"
                        :key="availableYear"
                        @click="changeYear(availableYear)"
                      >
                        {{ availableYear }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <a
                      href="#"
                      class="ml-4"
                      @click.prevent
                      v-bind:title="$t('vue.hospital.hospital_stats_info')"
                      v-b-tooltip.hover.bottom>
                      <i class="sf-icon sf-ic-info-full text-black-50"></i>
                    </a>
                  </div>
                  <div class="detail-stat-list d-inline-flex flex-column mt-4 mw-100 position-relative">
                    <StatisticCircleDetail
                      :mainStat="hospitalPatientSatisfaction"
                      statKey="patient_satisfaction"
                      :yearKey="hospitalPatientSatisfaction.year"
                      :extraKey="hospital.categoryId"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'akut' && !showSyndromeStats"
                      :mainStat="hospitalWoundInfections"
                      statKey="wound_infections"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'akut'"
                      :mainStat="hospitalPressureSores"
                      statKey="pressure_sores"
                      :yearKey="hospitalPressureSores.year"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'psych'"
                      :mainStat="hospitalSymptomBurdens"
                      statKey="symptom_burdens"
                      :yearKey="hospitalSymptomBurdens.year"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'reha'"
                      :mainStat="hospitalRehaSuccess"
                      statKey="reha_success"
                      :yearKey="hospitalRehaSuccess.year"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'akut'"
                      :mainStat="hospitalFalls"
                      statKey="falls"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'akut'"
                      :mainStat="hospitalRehospitalization"
                      statKey="rehospitalization"
                    />
                    <StatisticCircleDetail
                      v-if="hospital.categoryId === 'akut' && !showSyndromeStats"
                      :mainStat="hospitalRateRenewalImplantat"
                      statKey="rate_renewal_implantat"
                      :yearKey="hospitalRateRenewalImplantat.year"
                    />
                    <div class="loading-overlay-centered" v-if="loadingStats">
                      <div class="py-5 my-5 d-flex justify-content-center">
                        <FaIcon icon="circle-notch" iclass="fa-spin fa-3x text-primary"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 mt-4 mt-xl-0 d-print-none" v-if="hospital.lng && hospital.lat">
              <div id="hospital_gmap" :style="{ width: '100%', height: '400px'}"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid clinic-detail-syndrom pb-3" v-if="showSyndromeStats">
        <div class="bg-light p-4">
          <div class="syndrom-title mb-3 d-flex flex-column flex-md-row flex-wrap align-items-start align-items-md-center">
            <h2 class="d-inline text-secondary h3 mr-4">{{ $store.state.termSwitchOn ? hospital.syndromeStats.syndromeTerm : hospital.syndromeStats.syndromeName }}</h2>
            <span>
              <span class="h4">{{ hospital.name }}</span>
              <span class="ml-4"
                  v-bind:title="$t('vue.hospital.syndrome_stats_info')"
                  v-b-tooltip.hover.bottom>
                <i class="sf-icon sf-ic-info-full text-black-50"></i>
              </span>
            </span>
          </div>
          <div class="mt-3 clinic-syndrom-info d-md-inline-flex d-flex flex-column flex-md-row position-relative d-print-inline-block">
            <div class="d-inline-flex flex-column d-print-inline-block">
              <div class="my-2 d-flex" v-if="syndromeCaseNumbers.value !== null">
                <div class="flex-grow-1">
                  {{ $t('vue.syndrome.number_of_cases') }}
                  <span v-if="syndromeCaseNumbers.type === 'group' || syndromeCaseNumbers.type === 'overallGroup'">
                      {{ $t('vue.hospital.stats.group_value') }}
                  </span>
                </div>
                <div class="ml-5">
                  <strong class="text-secondary" v-if="syndromeCaseNumbers.value">
                    {{ syndromeCaseNumbers.value | formatNumber }}
                  </strong>
                  <strong class="text-secondary" v-else>0</strong>
                </div>
              </div>
              <div class="my-2 d-flex align-items-center" v-if="syndromeFlag.value !== null">
                <div class="flex-grow-1">
                  {{ $t('vue.syndrome.mortality') }}
                  <span v-if="syndromeFlag.type === 'group' || syndromeFlag.type === 'overallGroup'">
                      {{ $t('vue.hospital.stats.group_value') }}
                  </span>
                </div>
                <div class="ml-5">
                  <div
                    class="rounded-circle stat-circle"
                    v-bind:class="circleBgClass(syndromeFlag.value)"
                    v-b-tooltip.hover
                    :title="circleTooltipText(syndromeFlag.value)"
                  >
                    <strong class="text-white" v-if="syndromeFlag.mortality !== null">
                      {{ syndromeFlag.mortality }}
                    </strong>
                  </div>
                </div>
              </div>
              <StatisticCircleDetail
                :mainStat="syndromeWoundInfections"
                statKey="wound_infections"
                :yearKey="syndromeWoundInfections.year"
              />
              <StatisticCircleDetail
                :mainStat="syndromeRateRenewalImplantat"
                statKey="rate_renewal_implantat"
                :yearKey="syndromeRateRenewalImplantat.year"
              />
            </div>
            <div class="d-inline-flex flex-column ml-0 ml-md-5" v-if="mainSyndromeCaseNumbers.mainSyndromeCaseNumbers !== null && mainSyndromeCaseNumbers.percentage">
              <div class="my-2 mx-0 mx-md-3">
                <span>{{ $t('vue.syndrome.main_number_of_cases', { main_syndrome_name: mainSyndromeCaseNumbers.mainSyndromeName, main_number_of_cases: $formatNumber(mainSyndromeCaseNumbers.mainSyndromeCaseNumbers)}) }}</span><br>
                <span>{{ $t('vue.syndrome.percentage', { syndrome_name: mainSyndromeCaseNumbers.syndromeName, percentage: Number.parseFloat(mainSyndromeCaseNumbers.percentage * 100).toFixed(1)}) }}</span>
              </div>
            </div>
            <div class="loading-overlay-centered" v-if="loadingStats">
              <div class="d-flex justify-content-center">
                <FaIcon icon="circle-notch" iclass="fa-spin fa-3x text-primary"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loading">
      <div class="py-5 d-flex justify-content-center">
        <FaIcon icon="circle-notch" iclass="fa-spin fa-3x text-primary"/>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-danger">
        {{ $t('vue.hospital.not_found') }}
      </div>
    </div>
  </div>
</template>

<script>
import { copyTextToClipboard } from '@/utils/helpers'
import statisticsMixin from '@/mixins/statistics-mixin'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Search from '@/components/Search'
import StatisticCircleDetail from '@/components/StatisticCircleDetail'
import HospitalRating from '@/components/HospitalRating'
import PrintHeaderSimple from '@/components/PrintHeaderSimple'

export default {
  mixins: [statisticsMixin],
  components: {
    Search,
    StatisticCircleDetail,
    PrintHeaderSimple,
    HospitalRating
  },
  data() {
    return {
      loading: false,
      loadingStats: false
    }
  },
  created() {
    this.loading = true
    this.getHospital(this.$route.fullPath).then(() => this.loading = false);
  },
  metaInfo() {
    return {
      title: this.filter.syndromeId ? this.$t("vue.hospital.syndrome.page_title", {0: this.hospital.name, 1: this.filter.syndromeName}) : this.$t("vue.hospital.page_title", {0: this.hospital.name}),
      meta: [
        {
          name: 'description',
          content: this.filter.syndromeId ? this.$t("vue.hospital.syndrome.meta_description", {0: this.hospital.name, 1: this.filter.syndromeName}) : this.$t("vue.hospital.meta_description", {0: this.hospital.name})
        }
      ],
      link: [
        {
          rel: 'alternate',
          href: this.languageSwitcherUrls[this.$i18n.locale == "de" ? "fr" : "de"],
          hreflang: this.$i18n.locale == "de" ? "fr" : "de"
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getHospitalUrl',
      'languageSwitcherUrls',
      'getHospitalsUrl'
    ]),
    hospital() {
      return this.$store.state.hospital
    },
    filter() {
      return this.$store.state.filter
    },
    paginator() {
      return this.$store.state.paginator
    },
    groupName() {
      return this.hospital.hospitalGroup || this.hospital.hospitalOverallGroup
    },
  },
  methods: {
    ...mapActions([
      'getHospital'
    ]),
    ...mapMutations([
      'setHospitals',
    ]),
    changeYear(year) {
      const newUrl = this.getHospitalUrl({
        lang: this.$i18n.locale,
        year: year,
        hospitalUrlKey: this.hospital.urlKey,
        syndromeUrlKey: this.hospital.syndromeStats ? this.hospital.syndromeStats.syndromeUrlKey : null
      })
      this.$router.replace(newUrl);
      this.loadingStats = true;
    },
    isActiveYear(year) {
      return this.hospital.hospitalStats.year === year
    },
    textColorClass(flag) {
      if (flag === null) {
        return "text-muted";
      } else if (flag < 0.5) {
        return "text-danger";
      } else if (flag < 1.5) {
        return "text-body";
      } else {
        return "text-success";
      }
    },
    circleBgClass(flag) {
      if (flag === null) {
        return "border";
      } else if (flag < 0.5) {
        return "bg-danger";
      } else if (flag < 1.5) {
        return "bg-dark";
      } else {
        return "bg-success";
      }
    },
    getFlagLabel(statKey, flag, year, extraKey) {
      let defaultKey = 'vue.' + statKey + '.' + (extraKey ? extraKey + '.' : '') + flag
      if (year) {
        let keyWithYear = 'vue.' + statKey + '.' + (extraKey ? extraKey + '.' : '') + year + '.' + flag
        if (this.$te(keyWithYear)) {
          return this.$t(keyWithYear)
        }
      }
      return this.$t(defaultKey)
    },
    copyToClipboard() {
      copyTextToClipboard(location.href)
      this.$toast(this.$t('toast.copy_link.message'))
    },
    goBack() {
      const categoryUrl = this.getHospitalsUrl({
        categoryUrlKey: this.$store.state.hospitalCategories.find(cat => cat.categoryId == this.hospital.categoryId).urlKey,
        syndromeUrlKey: this.hospital.syndromeStats ? this.hospital.syndromeStats.syndromeUrlKey : false,
        pageNum: null
      });
      if (this.$store.state.hospitals === false) {
        this.$router.push(categoryUrl);
      } else {
        this.$router.back();
      }
    },
    circleTooltipText: function (flag) {
      // only for mortality
      if (flag === null) {
        return this.$t('vue.rate.none');
      } else if (flag < 0.5) {
        return this.$t('vue.rate.more_2');
      } else if (flag < 1.5) {
        return this.$t('vue.rate.1_2');
      } else {
        return this.$t('vue.rate.less_1');
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.params.hospitalUrlKey == from.params.hospitalUrlKey && to.params.hospitalUrlKey == this.hospital.urlKey) {
        this.loadingStats = true;
      }
      if (!this.loadingStats) {
        this.loading = true;
      }
      this.setHospitals({hospitals: false});
      this.getHospital(to.fullPath).then(() => {
        this.loading = false;
        this.loadingStats = false;
      });
    }
  }
}
</script>
