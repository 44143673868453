var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column justify-content-center py-5" },
    [
      _c("div", { staticClass: "bg-light pt-4 border-top" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center pt-4" },
              [_c("Logo")],
              1
            ),
            _c("Search"),
            _c("div", { staticClass: "d-flex justify-content-center" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: "syndrome_filter_collapse",
                      expression: "'syndrome_filter_collapse'"
                    }
                  ],
                  staticClass: "collapsed",
                  attrs: { href: "#", role: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "sf-icon sf-ic-diagnosis-other mr-1"
                  }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("vue.filter.syndrome.show_list_btn")))
                  ])
                ]
              )
            ])
          ],
          1
        ),
        _c("div", { staticClass: "my-4" }, [_c("SyndromeFilter")], 1),
        _c("div", { staticClass: "mt-4" }, [_c("ClinicTypeLinks")], 1)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }