<template>
  <b-dropdown
    right
    no-caret
    variant="link"
    id="sorting-toggle"
    class="sorting-wrapper dropdown order-3 text-nowrap d-flex justify-content-end"
    toggle-class="d-flex py-3 px-4 align-items-center flex-grow-1 flex-md-grow-0 text-body text-left text-md-right"
    toggle-tag="a"
  >
    <template v-slot:button-content>
      <i class="sf-icon sf-ic-list-arrow icon-big"></i>
      <span class="d-flex flex-column ml-3">
        {{ $t('vue.sort') }}
        <span class="region-filter-value" v-if="sort">
          {{ $t('vue.sort.' + sort.toLowerCase()) }}
        </span>
      </span>
    </template>
    <b-dropdown-item
      :active="isActive('patient_satisfaction')"
      @click="setSorting('patient_satisfaction')"
      v-if="isVisible('patient_satisfaction')"
    >
      {{ $t('vue.sort.patient_satisfaction') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('wound_infections')"
      @click="setSorting('wound_infections')"
      v-if="isVisible('wound_infections')"
    >
      {{ $t('vue.sort.wound_infections') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('falls')"
      @click="setSorting('falls')"
      v-if="isVisible('falls')"
    >
      {{ $t('vue.sort.falls') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('pressure_sores')"
      @click="setSorting('pressure_sores')"
      v-if="isVisible('pressure_sores')"
    >
      {{ $t('vue.sort.pressure_sores') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('rehospitalization')"
      @click="setSorting('rehospitalization')"
      v-if="isVisible('rehospitalization')"
    >
      {{ $t('vue.sort.rehospitalization') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('symptom_burdens')"
      @click="setSorting('symptom_burdens')"
      v-if="isVisible('symptom_burdens')"
    >
      {{ $t('vue.sort.symptom_burdens') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('rate_renewal_implantat')"
      @click="setSorting('rate_renewal_implantat')"
      v-if="isVisible('rate_renewal_implantat')"
    >
      {{ $t('vue.sort.rate_renewal_implantat') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('reha_success')"
      @click="setSorting('reha_success')"
      v-if="isVisible('reha_success')"
    >
      {{ $t('vue.sort.reha_success') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('name_asc')"
      @click="setSorting('name_asc')"
      v-if="isVisible('name_asc')"
    >
      {{ $t('vue.sort.name_asc') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('name_desc')"
      @click="setSorting('name_desc')"
      v-if="isVisible('name_desc')"
    >
      {{ $t('vue.sort.name_desc') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('distance')"
      @click="setSorting('distance')"
      v-if="isVisible('distance')"
    >
      {{ $t('vue.sort.distance') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('number_of_cases')"
      @click="setSorting('number_of_cases')"
      v-if="isVisible('number_of_cases')"
    >
      {{ $t('vue.sort.number_of_cases') }}
    </b-dropdown-item>
    <b-dropdown-item
      :active="isActive('mortality_ratio')"
      @click="setSorting('mortality_ratio')"
      v-if="isVisible('mortality_ratio')"
    >
      {{ $t('vue.sort.mortality_ratio') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      sort: 'getSorting',
      filter: 'getFilter'
    }),
    ...mapGetters([
      'getHospitalsUrl'
    ])
  },
  methods: {
    ...mapActions([
      'getHospitals'
    ]),
    setSorting(sort) {
      this.$store.state.sortingChosen = true;
      let newUrl = this.getHospitalsUrl({sort, pageNum: null});
      this.$router.push(newUrl);
    },
    isActive(sort) {
      if (this.sort === null) {
        return false;
      }
      return sort.toLowerCase() === this.sort.toLowerCase();
    },
    isVisible(sort) {
      switch (sort) {
        case 'pressure_sores':
        case 'wound_infections':
        case 'falls':
        case 'rehospitalization':
        case 'rate_renewal_implantat':
          return this.filter.categoryId === 'akut'
        case 'symptom_burdens':
          return this.filter.categoryId === 'psych'
        case 'reha_success':
          return this.filter.categoryId === 'reha'
        case 'number_of_cases':
          return this.filter.categoryId === 'akut' && this.filter.syndromeId && this.filter.syndromeCode
        case 'mortality_ratio':
          return this.filter.categoryId === 'akut' && this.filter.syndromeId && this.filter.syndromeCode && ["F", "P"].indexOf(this.filter.syndromeCode.slice(-1)) == -1
        case 'distance':
          return !!this.filter.range;
        default:
          return true;
      }
    }
  }

}
</script>
