var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasStats
    ? _c(
        "div",
        {
          class:
            _vm.boxClass ||
            "mb-2 d-flex flex-column justify-content-end align-items-center"
        },
        [
          _c("small", {
            staticClass: "stat-circle-title w-100 text-center mb-2",
            domProps: { innerHTML: _vm._s(_vm.$t("vue." + _vm.statKey)) }
          }),
          _c("div", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.bottom",
                modifiers: { hover: true, bottom: true }
              }
            ],
            staticClass: "rounded-circle stat-circle",
            class: _vm.circleBgClass(_vm.mainStat),
            attrs: {
              tabindex:
                _vm.flags && Object.keys(_vm.flags).length ? "-1" : "none",
              id: "clinic-stats-" + _vm.hospitalId + "-" + _vm.statKey,
              title: _vm.circleTooltip(_vm.mainStat)
            }
          }),
          _vm.mainStat !== null && _vm.flags && Object.keys(_vm.flags).length
            ? _c(
                "b-popover",
                {
                  staticClass: "d-none",
                  attrs: {
                    target:
                      "clinic-stats-" + _vm.hospitalId + "-" + _vm.statKey,
                    triggers: "focus"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("vue." + _vm.statKey))
                              }
                            }),
                            _c(
                              "a",
                              {
                                staticClass: "text-dark float-right",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.$root.$emit(
                                      "bv::hide::popover",
                                      "clinic-stats-" +
                                        _vm.hospitalId +
                                        "-" +
                                        _vm.statKey
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "small",
                                  [
                                    _c("SpfrIcon", {
                                      attrs: { icon: "nav-close" }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3408111634
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "clinic-stats-detailed d-flex flex-wrap justify-content-center"
                    },
                    _vm._l(Object.keys(_vm.flags).sort(), function(key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass:
                            "clinic-stats-detailed-item d-flex flex-column align-items-center mb-3"
                        },
                        [
                          _c(
                            "small",
                            {
                              staticClass:
                                "text-wrap w-100 text-center mb-2 flex-grow-1"
                            },
                            [_vm._v(" " + _vm._s(_vm.getLabel(key)) + " ")]
                          ),
                          _c("div", {
                            staticClass: "rounded-circle stat-circle",
                            class: _vm.circleBgClass(_vm.flags[key])
                          })
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }