<template>
    <b-collapse id="region-filter-collapse" class="d-print-none">
        <div class="bg-light py-4">
            <div class="container">
                <div class="row justify-content-around">
                    <div class="col-md-6 col-lg-5">
                        <label for="locationTag">{{ $t('vue.filter.region.input_title') }}</label>
                        <autocomplete
                            id="locationTag"
                            name="locationId"
                            class=""
                            ref="reg_autocomplete"
                            input-class="form-control form-control-lg"
                            :results-display="locationFormattedDisplay"
                            source="/api/location-suggestions.json?q="
                            results-property="Object"
                            v-bind:placeholder="$t('vue.filter.region.input_placeholder')"
                            :initialValue="filter.locationId"
                            :initialDisplay="filter.locationName"
                            results-value="id"
                            v-on:selected="location_selected"
                            v-on:clear="location_clear"
                            v-on:nothingSelected="onEnterKey"
                            clearButtonIcon="sf-icon sf-ic-nav-close"
                        />
                        <div class="mt-4">
                            <button
                                type="button"
                                :class="`btn btn-outline-secondary btn-block ${isUsingLocationInternal ? 'active' : ''}`"
                                :disabled="!geolocationSupported"
                                v-on:click="resolveUserLocation()"
                            >
                                <i class="sf-icon sf-ic-locate mr-2"></i>{{isUsingLocationInternal ? $t('vue.filter.region.geolocation_mine_used') : $t('vue.filter.region.geolocation_mine_use')}}
                            </button>
                            <div class="alert alert-danger mt-2" v-if="geolocationNoPermissions">
                                {{ $t('vue.filter.region.geolocation_no_permissions') }}
                            </div>
                            <div class="alert alert-danger mt-2" v-if="geolocationFailed">
                                {{ $t('vue.filter.region.geolocation_failed') }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-5 mt-4 mt-md-0">
                        <label for="filter-range">{{ $t('vue.filter.region.range') }} <strong id="filter-range-value" class="text-secondary ml-3">{{range}}km</strong></label>
                        <div class="filter-range-slider-wrapper d-flex align-items-center">
                            <span class="mr-3">{{ $t('vue.filter.region.range_default') }}</span>
                            <input type="range" name="range" class="custom-range mt-1" min="3" max="350" id="filter-range" v-model="range" @change="rangeChanged"/>
                            <span class="text-nowrap ml-3">{{ $t('vue.filter.region.geolocation_default') }}</span>
                        </div>
                        <div class="mt-4">
                            <button type="button" class="btn btn-outline-dark btn-block" @click="reset_location_filter">{{ $t('vue.reset') }}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </b-collapse>
</template>

<script>
import Autocomplete from 'vuejs-auto-complete'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        Autocomplete
    },
    created() {
        this.range = this.filter.range || this.range;
        this.locationId = this.filter.locationId || this.locationId;
        this.locationName = this.filter.locationName || this.locationName;
        if (!!this.locationId) {
            this.lon = this.filter.lon;
            this.lat = this.filter.lat;
        }
        if(("geolocation" in navigator)) {
            this.geolocationSupported = true;
        } else {
            this.geolocationSupported = false;
        }
    },
    data() {
        return {
            geolocationNoPermissions: false,
            geolocationFailed: false,
            geolocationSupported: true,
            lat: null,
            lon: null,
            range: 15,
            locationId: null,
            locationName: null,
            isUsingLocationInternal: false
        }
    },
    computed: {
        ...mapGetters([
            'getHospitalsUrl'
        ]),
        ...mapGetters({
            sort: 'getSorting',
            filter: 'getFilter',
            syndromeId: 'getSyndromeId',
            categoryId: 'getCategoryId'
        }),
        isUsingLocation: {
            get() {
                return this.$store.state.isUsingLocation;
            },
            set(newValue) {
                this.$store.state.isUsingLocation = newValue;
                return newValue;
            }
        },
    },
    methods: {
        ...mapActions([
            'getHospitals'
        ]),
        locationFormattedDisplay (location) {
            location.href = "#";
            return location.zip + " " + location.city + " (" + location.kanton + ")"
        },
        resolveUserLocation: function () {
            var options = {
                enableHighAccuracy: true,
                timeout: 20500,
                maximumAge: 0
            };

            navigator.geolocation.getCurrentPosition(pos => {
                this.geolocationNoPermissions = false;
                this.geolocationFailed = false;
                if(pos) {
                    this.currentLocationSelected(pos.coords.latitude, pos.coords.longitude);
                    this.apply_location_filter();
                } else {
                    this.currentLocationSelected(null, null);
                    this.geolocationFailed = true;
                }
            }, err => {
                console.error(err)
                this.currentLocationSelected(null, null);
                if (err.code != 1) {
                    this.geolocationFailed = true;
                    this.geolocationNoPermissions = false;
                } else {
                    this.geolocationNoPermissions = true;
                    this.geolocationFailed = false;
                }
            }, options)
        },
        currentLocationSelected: function (lat, lon) {
            this.lat = lat;
            this.lon = lon;
            if (lat !== null && lon !== null) {
                this.isUsingLocationInternal = true;
            }

            this.locationId = null;
            this.locationName = null;
        },
        onEnterKey() {
            const results = this.$refs.reg_autocomplete.results;
            if (results.length === 1) {
                this.location_selected({selectedObject: results[0]});
                const text = this.locationFormattedDisplay(results[0])
                this.$refs.reg_autocomplete.display = text;
                this.$refs.reg_autocomplete.selectedDisplay = text;
                this.$refs.reg_autocomplete.value = results[0].id;
            }
        },
        location_selected: function (object) {
            this.lat = null;
            this.lon = null;
            this.isUsingLocationInternal = false;
            this.geolocationFailed = false;
            this.geolocationNoPermissions = false;
            if(object){
                const locationName = this.locationFormattedDisplay(object.selectedObject);
                const locationId = object.selectedObject.id;

                this.locationId = locationId;
                this.locationName = locationName;

                this.apply_location_filter();
            }
        },
        location_clear() {
            this.locationId = null;
            this.locationName = null;
        },
        apply_location_filter() {
            if (this.locationId !== null) {
                this.isUsingLocation = false;
                const newUrl = this.getHospitalsUrl({
                    locationId: this.locationId,
                    range: this.range,
                    pageNum: null,
                    sort: this.$store.state.sortingChosen ? this.sort : 'DISTANCE',
                    lat: null,
                    lon: null
                });
                this.$router.push(newUrl);
            } else {
                this.isUsingLocation = true;
                const newUrl = this.getHospitalsUrl({
                    lat: this.lat,
                    lon: this.lon,
                    range: this.range,
                    pageNum: null,
                    sort: this.$store.state.sortingChosen ? this.sort : 'DISTANCE',
                    locationId: null
                });
                this.$router.push(newUrl);
            }
        },
        reset_location_filter() {
            this.lat = null;
            this.lon = null;
            this.range = 15;
            this.locationId = null;
            this.locationName = null;
            this.isUsingLocation = false;
            this.isUsingLocationInternal = false;
            this.$refs.reg_autocomplete.clear();
            const newUrl = this.getHospitalsUrl({
                lat: null,
                lon: null,
                locationId: null,
                range: null,
                pageNum: null,
                sort: this.sort == 'DISTANCE' ? null : this.sort
            });
            this.$router.push(newUrl);
        },
        rangeChanged(e) {
            if ((this.lat && this.lon) || this.locationId) {
                this.apply_location_filter();
            }
        }
    },
    watch: {
        filter(newFilter, oldFilter) {
            this.range = newFilter.range || this.range;
            this.locationId = newFilter.locationId || this.locationId;
            this.locationName = newFilter.locationName || this.locationName;
            if (!!this.locationId) {
                this.lon = newFilter.lon || this.lon;
                this.lat = newFilter.lat || this.lat;
            }
        }
    }
}

</script>


