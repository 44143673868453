<template>
  <div class="card bg-light border-0 h-100">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="card-body-info flex-grow-1">
          <h5 class="card-title">
            <router-link :to="hospitalUrl(hospital)"
               v-bind:title="hospital.name">
              {{ hospital.name }}
            </router-link>
          </h5>
          <h6 class="card-subtitle text-muted" v-if="hospital.categoryId === 'reha'">
            {{ hospital.zip }} {{ hospital.city }}
          </h6>
          <h6 class="card-subtitle text-muted" v-else>
            {{ hospital.zip }} {{ hospital.city }} - {{ hospital.typeName }}
          </h6>
        </div>
      </div>
      <hr>
      <p class="card-text">
        {{ $t('vue.hospital.general_stats') }}
        <a :href="definitionIdLink.url" class="float-right card-info-link" :title="definitionIdLink.label">
          <i class="sf-icon sf-ic-info-full"></i>
        </a>
      </p>
      <div class="clinic-stats d-flex justify-content-start">
        <StatisticCircle
          :mainStat="hospitalPatientSatisfaction.value"
          :hasStats="hospitalPatientSatisfaction.hasStats"
          :year="hospitalPatientSatisfaction.year"
          :flags="hospitalPatientSatisfaction.flags"
          statKey="patient_satisfaction"
          :hospitalId="hospital.id"
          :extraKey="hospital.categoryId"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'akut' && !showSyndromeStats"
          :mainStat="hospitalWoundInfections.value"
          :hasStats="hospitalWoundInfections.hasStats"
          :year="hospitalWoundInfections.year"
          :flags="hospitalWoundInfections.flags"
          statKey="wound_infections"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'akut'"
          :mainStat="hospitalFalls.value"
          :hasStats="hospitalFalls.hasStats"
          :year="hospitalFalls.year"
          :flags="false"
          statKey="falls"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'akut'"
          :mainStat="hospitalPressureSores.value"
          :hasStats="hospitalPressureSores.hasStats"
          :year="hospitalPressureSores.year"
          :flags="hospitalPressureSores.flags"
          statKey="pressure_sores"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'akut'"
          :mainStat="hospitalRehospitalization.value"
          :hasStats="hospitalRehospitalization.hasStats"
          :year="hospitalRehospitalization.year"
          :flags="false"
          statKey="rehospitalization"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'akut' && !showSyndromeStats"
          :mainStat="hospitalRateRenewalImplantat.value"
          :hasStats="hospitalRateRenewalImplantat.hasStats"
          :year="hospitalRateRenewalImplantat.year"
          :flags="hospitalRateRenewalImplantat.flags"
          statKey="rate_renewal_implantat"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'psych'"
          :mainStat="hospitalSymptomBurdens.value"
          :hasStats="hospitalSymptomBurdens.hasStats"
          :year="hospitalSymptomBurdens.year"
          :flags="hospitalSymptomBurdens.flags"
          statKey="symptom_burdens"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
        <StatisticCircle
          v-if="hospital.categoryId === 'reha'"
          :mainStat="hospitalRehaSuccess.value"
          :hasStats="hospitalRehaSuccess.hasStats"
          :year="hospitalRehaSuccess.year"
          :flags="hospitalRehaSuccess.flags"
          statKey="reha_success"
          :hospitalId="hospital.id"
          :extraKey="false"
        />
      </div>
      <div v-if="showSyndromeStats">
        <hr>
        <p class="card-text text-secondary">
          <strong>{{ $store.state.termSwitchOn ? hospital.syndromeStats.syndromeTerm : hospital.syndromeStats.syndromeName }}</strong>
        </p>
        <div class="row syndrom-stats align-items-center mx-n1">
          <div class="col-sm-6 col-12 px-1">
            <div>{{ $t('vue.syndrome.number_of_cases') }}</div>
            <div class="row">
              <div class="col-md-8" v-if="syndromeCaseNumbers.value != null">
                <span>{{ $t('vue.syndrome.number_of_cases.current') }}</span>
              </div>
              <div class="col-md-4 syndrom-count" v-if="syndromeCaseNumbers.value != null">
                <strong class="text-secondary" v-if="syndromeCaseNumbers.value">
                  {{ syndromeCaseNumbers.value | formatNumber }}
                </strong>
                <strong class="text-secondary" v-else>0</strong>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-12 mt-3 mt-sm-0 px-1" v-if="syndromeCaseNumbers.value != null && mainSyndromeCaseNumbers.mainSyndromeCaseNumbers !== null && mainSyndromeCaseNumbers.percentage">
            <span>{{ $t('vue.syndrome.main_number_of_cases', { main_syndrome_name: mainSyndromeCaseNumbers.mainSyndromeName, main_number_of_cases: $formatNumber(mainSyndromeCaseNumbers.mainSyndromeCaseNumbers)}) }}</span><br>
            <span>{{ $t('vue.syndrome.percentage', { syndrome_name: mainSyndromeCaseNumbers.syndromeName, percentage: Number.parseFloat(mainSyndromeCaseNumbers.percentage * 100).toFixed(1)}) }}</span>
          </div>
          <div class="col-12 px-1 mt-3">
            <div class="clinic-stats d-flex justify-content-start">
              <StatisticCircle
                :mainStat="syndromeWoundInfections.value"
                :hasStats="syndromeWoundInfections.hasStats"
                :flags="syndromeWoundInfections.flags"
                statKey="wound_infections"
                :hospitalId="hospital.id"
              />
              <StatisticCircle
                v-if="hospital.categoryId === 'akut'"
                :mainStat="syndromeRateRenewalImplantat.value"
                :hasStats="syndromeRateRenewalImplantat.hasStats"
                :flags="syndromeRateRenewalImplantat.flags"
                statKey="rate_renewal_implantat"
                :hospitalId="hospital.id"
              />
              <div class="mb-2 d-flex flex-column justify-content-end align-items-center" v-if="syndromeFlag.value !== null">
                <small class="stat-circle-title w-100 text-center mb-2">
                  {{ $t('vue.syndrome.mortality') }}
                </small>
                <div
                  class="rounded-circle stat-circle"
                  :class="circleBgClass(syndromeFlag.value)"
                  v-b-tooltip.hover.bottom
                  :title="circleTooltipText(syndromeFlag.value)"
                  :id="`clinic-stats-${hospital.id}-mortality`"
                  tabindex="-1"
                >
                  <strong class="text-white" v-if="syndromeFlag.mortality !== null">
                    {{ syndromeFlag.mortality }}
                  </strong>
                </div>
                <b-popover
                  class="d-none"
                  :target="`clinic-stats-${hospital.id}-mortality`"
                  triggers="focus"
                >
                  <template #title>
                    {{ $t('syndrome.mortality') }}
                    <a href="#" class="text-dark float-right" @click.prevent="$root.$emit('bv::hide::popover', `clinic-stats-${hospital.id}-mortality`)"><small><SpfrIcon icon="nav-close"/></small></a>
                  </template>
                  {{ $t("syndrome.mortality.popover") }}
                </b-popover>
              </div>
              <div v-for="(item, index) in (new Array(additionalStatsCount))" :key="index"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticCircle from '@/components/StatisticCircle'
import statisticsMixin from '@/mixins/statistics-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [statisticsMixin],
  components: {
    StatisticCircle,
  },
  props: [
      'hospital',
  ],
  computed: {
    ...mapGetters(
      [
        'getHospitalUrl',
        'definitionLinks'
      ]
    ),
    additionalStatsCount() {
      const statTypes = ["hospitalPatientSatisfaction", "hospitalWoundInfections", "hospitalFalls", "hospitalPressureSores", "hospitalRehospitalization", "hospitalRateRenewalImplantat"];
      const synStatTypes = ["syndromeWoundInfections", "syndromeRateRenewalImplantat", "mortality"];
      const stl = statTypes.filter(st => {
        if (!this[st].hasStats) {
          return false
        } else {
          switch(st) {
            case "hospitalWoundInfections":
            case "hospitalRateRenewalImplantat":
              return !this.showSyndromeStats;
            default:
              return true;
          }
        }
      }).length;
      const sstl = synStatTypes.filter(sst => {
        switch(sst) {
          case "mortality":
            return this.syndromeFlag.value !== null;
          default:
            return this[sst].hasStats;
        }
      }).length;
      return stl - sstl;
    },
    definitionIdLink() {
      switch(this.hospital.categoryId) {
        case "akut":
          if (this.hospital.syndromeStats != null) {
            switch(this.hospital.syndromeStats.syndromeCode.substr(-1)) {
              case "M":
                return this.definitionLinks.akutM
              case "P":
                return this.definitionLinks.akutP
              case "F":
                return this.definitionLinks.akutF
              default:
                return this.definitionLinks.akut
            }
          }
          return this.definitionLinks.akut
        case "reha":
          return this.definitionLinks.reha
        case "psych":
          return this.definitionLinks.psych
      }
    },
    filter() {
      return this.$store.state.filter
    },
    hospitalTypeIcon() {
      let hospitalType = false;
      switch(this.hospital.typeId) {
        case "Z":
          hospitalType = "center";
          break;
        case "R":
          hospitalType = "regional";
          break;
        case "SP":
          hospitalType = "special";
          break;
        case "U":
          hospitalType = "university";
          break;
      }
      return hospitalType;
    },
  },
  methods: {
    hospitalUrl: function (hospital) {
      return this.getHospitalUrl({
        lang: this.$i18n.locale,
        hospitalUrlKey: hospital.urlKey,
        syndromeUrlKey: this.filter.syndromeUrlKey
      })
    },
    circleBgClass: function (flag) {
      if (flag === null) {
        return "border";
      } else if (flag < 0.5) {
        return "bg-danger";
      } else if (flag < 1.5) {
        return "bg-dark";
      } else {
        return "bg-success";
      }
    },
    circleTooltipText: function (flag) {
      // only for mortality
      if (flag === null) {
        return this.$t('vue.rate.none');
      } else if (flag < 0.5) {
        return this.$t('vue.rate.more_2');
      } else if (flag < 1.5) {
        return this.$t('vue.rate.1_2');
      } else {
        return this.$t('vue.rate.less_1');
      }
    }
  }
}
</script>
