var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "toast-container" }, [
      _c(
        "div",
        {
          staticClass: "toast fade hide",
          attrs: {
            role: "alert",
            "aria-live": "assertive",
            "aria-atomic": "true",
            id: "copy-to-clipboard-toast"
          }
        },
        [
          _c("div", { staticClass: "toast-header" }, [
            _c("img", {
              staticClass: "rounded mr-2",
              attrs: {
                src: require("../assets/toast-icon.png"),
                height: "16",
                width: "16",
                alt: "Spitalfinder Logo"
              }
            }),
            _c("strong", { staticClass: "mr-auto" }, [
              _vm._v(_vm._s(_vm.$t("vue.toast.default.title")))
            ]),
            _vm._m(0)
          ]),
          _c("div", { staticClass: "toast-body" }, [
            _vm._v(" " + _vm._s(_vm.$t("vue.toast.copy_link.message")) + " ")
          ])
        ]
      )
    ]),
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-light border-bottom bg-white fixed-top d-print-none"
      },
      [
        _c(
          "div",
          { staticClass: "d-flex w-100 align-items-center" },
          [
            _c(
              "router-link",
              {
                class: "navbar-brand pt-2 pb-0 " + _vm.logoClass,
                attrs: { to: "/", title: _vm.$t("vue.link.homepage.title") },
                nativeOn: {
                  click: function($event) {
                    return _vm.resetHospitalList($event)
                  }
                }
              },
              [_c("Logo")],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-grow-1 justify-content-end" },
              [
                _c(
                  "ul",
                  { staticClass: "nav" },
                  [
                    _vm._l(_vm.navigationItems, function(item) {
                      return _c(
                        "li",
                        { key: item.id, staticClass: "nav-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: item.url, title: item.label }
                            },
                            [_c("span", [_vm._v(_vm._s(item.label))])]
                          )
                        ]
                      )
                    }),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: { variant: "link" },
                            scopedSlots: _vm._u([
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$i18n.locale.toUpperCase()) +
                                        " "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _vm.$i18n.locale == "fr"
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: _vm.deLink },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeLanguage($event, "de")
                                      }
                                    }
                                  },
                                  [_vm._v("DE")]
                                )
                              : _vm._e(),
                            _vm.$i18n.locale == "de"
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { href: _vm.frLink },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeLanguage($event, "fr")
                                      }
                                    }
                                  },
                                  [_vm._v("FR")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "ml-2 mb-1 close",
        attrs: {
          type: "button",
          "data-dismiss": "toast",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }