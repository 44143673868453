<template>
  <div class="w-100">
    <Search/>
    <ClinicTypeLinks/>
    <ClinicToolbar/>
    <PrintHeaderList/>
    <div class="container mt-4 d-print-none">
      <div class="row justify-content-center" v-if="!loading && paginator.totalPages">
        <paginate
            v-model="paginator.page"
            :page-count="paginator.totalPages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="changePage"
            :prev-text="$t('vue.paginate.prev')"
            :next-text="$t('vue.paginate.next')"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
        />
      </div>
    </div>
    <div class="container-fluid" v-if="loading">
      <div class="py-5 d-flex justify-content-center">
        <FaIcon icon="circle-notch" iclass="fa-spin fa-3x text-primary"/>
      </div>
    </div>
    <div class="clinic-list position-relative py-3" v-else-if="!loading && hospitals.length > 0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-4 py-3 clinic-col" v-for="hospital in hospitals" :key="hospital.id">
            <HospitalBox :hospital="hospital"/>
          </div>
        </div>
      </div>
      <div class="loading-overlay" v-if="loadingPage">
        <div class="py-5 my-5 d-flex justify-content-center">
          <FaIcon icon="circle-notch" iclass="fa-spin fa-3x text-primary"/>
        </div>
      </div>
    </div>
    <div class="container" v-if="hospitals.length == 0 && filter.range !== null && !loading && !loadingPage">
      <b-alert variant="warning" show>{{ $t('vue.hospitals.not_found.with_region_filter') }}</b-alert>
    </div>
    <div class="container" v-if="hospitals.length == 0 && filter.range == null && !loading && !loadingPage">
      <b-alert variant="warning" show>{{ $t('vue.hospitals.not_found') }}</b-alert>
    </div>
    <div class="container d-print-none mb-5">
      <div class="row justify-content-center" v-if="!loading && paginator.totalPages">
        <paginate
            v-model="paginator.page"
            :page-count="paginator.totalPages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="changePage"
            :prev-text="$t('vue.paginate.prev')"
            :next-text="$t('vue.paginate.next')"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :next-link-class="'page-link'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Search from '@/components/Search'
import ClinicTypeLinks from '@/components/ClinicTypeLinks'
import ClinicToolbar from '@/components/ClinicToolbar'
import HospitalBox from '@/components/HospitalBox'
import PrintHeaderList from '@/components/PrintHeaderList'

export default {
  components: {
    Search,
    ClinicTypeLinks,
    ClinicToolbar,
    HospitalBox,
    PrintHeaderList
  },
  data() {
    return {
      loading: false,
      loadingPage: false
    }
  },
  created() {
    if (this.$store.state.hospitals === false || (this.$route.params.categoryUrlKey != this.filter.categoryUrlKey && this.$route.params.syndromeUrlKey != this.filter.syndromeUrlKey)) {
      this.loading = true;
      this.getHospitals(this.$route.fullPath).then(() => this.loading = false);
    } else {
      this.getLanguageUrls(this.$route.fullPath)
    }
  },
  metaInfo() {
    return {
      title: this.filter.syndromeId ? this.$t("vue.hospitals.syndrome.page_title", {0: this.filter.syndromeName}) : this.$t("vue.hospitals.page_title"),
      meta: [
        {
          name: 'description',
          content: this.filter.syndromeId ? this.$t("vue.hospitals.syndrome.meta_description", {0: this.filter.syndromeName}) : this.$t("vue.hospitals.meta_description")
        }
      ],
      link: [
        {
          rel: 'alternate',
          href: this.languageSwitcherUrls[this.$i18n.locale == "de" ? "fr" : "de"],
          hreflang: this.$i18n.locale == "de" ? "fr" : "de"
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getHospitalsUrl',
      'languageSwitcherUrls'
    ]),
    hospitals: function () {
      return this.$store.state.hospitals
    },
    paginator() {
      return this.$store.state.paginator
    },
    filter() {
      return this.$store.state.filter
    }
  },
  methods: {
    ...mapActions([
      'getHospitals',
      'getLanguageUrls'
    ]),
    changePage(pageNum) {
        const newUrl = this.getHospitalsUrl({
          pageNum: pageNum,
          sort: this.$route.query.sort,
        })

        this.$router.push(newUrl);
        this.loadingPage = true;
    }
  },
  watch: {
    $route(to, from) {
      if (!this.loadingPage) {
        this.loading = true;
      }
      this.getHospitals(to.fullPath).then(() => {
        this.loading = false;
        this.loadingPage = false;
      });
    }
  }
}
</script>
