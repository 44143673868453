<template>
    <div class="h-100 d-flex flex-column justify-content-center py-5">
        <div class="bg-light pt-4 border-top">
            <div class="container">
                <div class="d-flex justify-content-center pt-4">
                    <Logo/>
                </div>
                <Search/>
                <div class="d-flex justify-content-center">
                    <a href="#" @click.prevent class="collapsed" v-b-toggle="'syndrome_filter_collapse'" role="button">
                        <i class="sf-icon sf-ic-diagnosis-other mr-1"></i>
                        <span>{{ $t('vue.filter.syndrome.show_list_btn') }}</span>
                    </a>
                </div>
            </div>
            <div class="my-4">
                <SyndromeFilter/>
            </div>
            <div class="mt-4">
                <ClinicTypeLinks />
            </div>
        </div>
    </div>
</template>

<script>
    import Search from '@/components/Search'
    import Logo from '@/components/Logo'
    import SyndromeFilter from '@/components/SyndromeFilter'
    import ClinicTypeLinks from '@/components/ClinicTypeLinks'
    export default {
        components: {
            Search,
            SyndromeFilter,
            ClinicTypeLinks,
            Logo
        },
        metaInfo() {
            return {
                title: this.$t("vue.home.page_title"),
                meta: [
                    {
                        name: 'description',
                        content: this.$t("vue.home.meta_description")
                    }
                ],
                links: [
                    {
                        rel: 'alternate',
                        href: this.$i18n.locale == "de" ? location.href.replace("spitalfinder", "hostofinder") : location.href.replace("hostofinder", "spitalfinder"),
                        hreflang: this.$i18n.locale == "de" ? "fr" : "de"
                    }
                ]
            }
        }
    }
</script>
